import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway'
import Balances from '@/models/Balances'
import CreateContractSignDataV2 from '@/models-ts/sign-process/CreateContractSignDataV2'
import Currency from '@/models-ts/Currency'
import GasPriceNew from '@/partials/GasPriceNew/GasPriceNew.vue'
import PaymentDetail, { PaymentDetailsTypes } from '@/models-ts/sign-process/PaymentDetail'
import Wallet from '@/models-ts/Wallet'
import WalletSelect from '@/partials/WalletSelect/WalletSelect.vue'
import ratesMixin from '@/mixins/ratesMixin'
import {
  Blockchain,
  BlockchainNames,
  EVM_BLOCKCHAIN,
  getBlockchainNameByChainId,
  getNameByWalletGroup,
  getTronArtifactsNameByNode,
  WalletGroup,
} from '@/constants/blockchain'
import { DATE_TIME_FORMAT_BY_MERIDIEM } from '@/constants/utils/date'
import { addSeconds, convertToLocal } from '@/utils/date'
import { formatCurrency } from '@/utils/moneyFormat'
import { getBalances } from '@/utils-ts/balance'
import { getBlockchainIcon } from '@/utils-ts/wallet'
import snackMixin from '@/mixins/snackMixin'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { convertFromUsd, convertToUsd, getCurrency } from '@/utils-ts/currencies'
import AccountType from '@/constants/user/AccountType'
import { AccountTypes } from '@/constants/user/accountTypes'
import { ProviderByBlockchain } from '@/constants/providers'

export default Vue.extend<any, any, any, any>({
  name: 'lx-hiring-freelancer-modal',
  mixins: [clickaway, ratesMixin, snackMixin],
  components: { GasPriceNew, UserInfo, WalletSelect },
  data () {
    return {
      balances: {},
      balanceLoading: true,
      showGasPrice: false,
      walletProcessing: false,
      currencyProcessing: false,
      selectedBlockchain: null as Blockchain | null,
      selectedCurrency: null as Currency | null,
      selectedWallet: null as Wallet | null,
      WalletGroup,
    }
  },
  computed: {
    ...mapState('signProcess', {
      signData: (state: any): CreateContractSignDataV2 => state.signData,
      loading: (state: any): boolean => state.loading,
      approving: (state: any): boolean => state.approving,
      signing: (state: any): boolean => state.signing,
      isAvailable: (state: any): boolean => state.isAvailable,
      isConnected: (state: any): boolean => state.isConnected,
      chainIds: (state: any): number | null => state?.chainIds,
      addresses: (state: any): string => state.addresses,
      tronLinkNode: (state: any): string => state.tronLinkNode,
    }),
    ...mapGetters({
      chainId: 'signProcess/chainId',
      address: 'signProcess/address',
    }),
    ...mapState('user', {
      wallets: (state: any): Wallet[] => state.wallets,
      userType: (state: any): AccountTypes => state.type,
    }),
    initialLoading () {
      return !this.ratesLoaded || this.balanceLoading
    },
    accountType () {
      return AccountType.getType(this.userType)
    },
    allFieldsDisabled (): boolean {
      return this.approving || this.signing
    },
    depositAmount () {
      const paymentDetails = this.signData.paymentDetails as PaymentDetail[]
      return paymentDetails.find(item => item.type === PaymentDetailsTypes.DepositAmount)
    },
    amountDetails () {
      const details: PaymentDetail[] = this.signData.approve ? this.signData.approve.details : this.signData.paymentDetails
      return details.filter(item => item.type !== PaymentDetailsTypes.DepositAmount)
    },
    contractName () {
      return this.signData.name
    },
    freelancer () {
      return this.signData.freelancer
    },
    deadline () {
      return convertToLocal(addSeconds(Date.now(), this.signData.deadline), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    currentBalances () {
      if (this.balances[this.selectedWallet.address]) {
        const balances = this.balances[this.selectedWallet.address][this.selectedBlockchain]
        return (this.currencyOptions as Array<Currency>)
          .map(currency => {
            const balance = new BigNumber(balances[currency.name] || '0').dividedBy(currency.decimalsDivider)
            return {
              name: currency.name,
              formattedBalance: `${formatCurrency(balance, { currency })} ${currency.name}`
            }
          })
          .reduce((acc, { name, ...balances }) => ({ ...acc, [name]: balances }), {})
      }
      return null
    },
    walletOptions () {
      // TODO TRX if we dont have wallets
      if (this.signData?.freelancerWallet) {
        if (this.signData?.freelancerWallet?.group === WalletGroup.TronLink) {
          return this.wallets.filter((wallet: Wallet) => wallet.group === WalletGroup.TronLink)
        } else {
          return this.wallets.filter((wallet: Wallet) => wallet.group !== WalletGroup.TronLink)
        }
      } else {
        const foundTron: boolean = this.signData.preferredCurrencies
          .some((currency: Currency) => Blockchain.Tron === currency.blockchain)
        const foundEth: boolean = this.signData.preferredCurrencies
          .some((currency: Currency) => EVM_BLOCKCHAIN.includes(currency.blockchain))
        let wallets = this.wallets.filter((wallet: Wallet) => {
          if (foundEth && [WalletGroup.Cloud, WalletGroup.WalletConnect, WalletGroup.Metamask].includes(wallet.group)) {
            return true
          }
          if (foundTron && WalletGroup.TronLink === wallet.group) {
            return true
          }
          return false
        })
        return wallets
      }
    },
    blockchainOptions () {
      return this.selectedWallet?.group === WalletGroup.TronLink
        ? [Blockchain.Tron]
          .filter(blockchain => this.signData.preferredCurrencies.some((curr: Currency) => curr.blockchain === blockchain))
          .map(key => ({ key, name: BlockchainNames[key] }))
        : EVM_BLOCKCHAIN
          .filter(blockchain => this.signData.preferredCurrencies.some((curr: Currency) => curr.blockchain === blockchain))
          .map(key => ({ key, name: BlockchainNames[key] }))
    },
    selectedBlockchainOption () {
      return this.blockchainOptions.find((opt: { key: number }) => opt.key === this.selectedBlockchain)
    },
    currencyOptions () {
      return (this.signData.preferredCurrencies as Currency[]).filter(curr => curr.blockchain === this.selectedBlockchain)
    },
    balanceErrors () {
      return this.signData.missingBalance
    },
    hasMissingBalanceErrors () {
      return !!this.balanceErrors.length
    },
    walletError () {
      const walletGroup = this.selectedWallet.group
      if ([WalletGroup.Metamask, WalletGroup.WalletConnect, WalletGroup.TronLink].includes(walletGroup)) {
        const walletType = getNameByWalletGroup(walletGroup)
        if (!this.isAvailable) {
          return `${walletType} not found or not installed`
        }
        if (!this.isConnected) {
          return `${walletType} isn't connected or accounts are unavailable`
        }
        if (WalletGroup.WalletConnect === walletGroup) {
          if (!(this.addresses || []).includes(this.signData?.wallet?.address)) {
            return `Selected (Current connected) addresses in ${walletType} doesn’t match the address provided in the contract`
          }
        } else {
          if (this.address !== this.signData?.wallet?.address) {
            return `Selected (Current connected) address in ${walletType} doesn’t match the address provided in the contract`
          }
        }

        if (WalletGroup.TronLink === walletGroup) {
          if (ProviderByBlockchain[Blockchain.Tron] !== this.tronLinkNode) {
            return `Selected tron link network does not match with ${getTronArtifactsNameByNode(this.signData?.tronLinkNode)}`
          }
        } else {
          if (!(this.chainIds || []).includes(this.signData?.chainId)) {
            // eslint-disable-next-line max-len
            return `Selected network(s)
            ${WalletGroup.WalletConnect === walletGroup ? '' : ` ${getBlockchainNameByChainId(this.chainId)}`}
            in ${walletType} does not match with current ${getBlockchainNameByChainId(this.signData.chainId)}`
          }
        }
      }
      if (this.hasMissingBalanceErrors && !this.processing) {
        const currencies = this.balanceErrors.map((err: any) => err.currency.displayName)
        return `You do not have sufficient ${currencies.join(' and ')} to cover these costs`
      }
      return ''
    },
    jobMiningRewards (): number | string {
      if (this.accountType.isPremium) {
        const paymentDetails = this.signData.paymentDetails as PaymentDetail[]
        const depositAmount = paymentDetails.find(item => item.type === PaymentDetailsTypes.DepositAmount)
        const rewards = new BigNumber(depositAmount?.amount || 0)
          .dividedBy(this.signData.currency.decimalsDivider)
          .multipliedBy(this.accountType.returnCommission * 0.05 / 100)
        if (rewards.isGreaterThan(0)) {
          const TIME = getCurrency({ blockchain: Blockchain.Ethereum, value: 'TIME' })!
          // ToDo: combine these two methods into one convertCurrency
          const usdReward = convertToUsd({
            value: rewards,
            currency: this.signData.currency,
            rates: this.rates,
            format: false
          })
          const timeRewards = convertFromUsd({ value: usdReward, currency: TIME, rates: this.rates })
          return formatCurrency(timeRewards, { currency: TIME })
        }
      }
      return 0
    },
    processing () {
      return this.walletProcessing || this.currencyProcessing || this.signing || this.approving
    },
    approveBtnDisabled () {
      return !!(this.processing || this.walletError)
    },
    signBtnDisabled () {
      return !!(this.processing || this.walletError || this.hasMissingBalanceErrors)
    },
  },
  watch: {
    'signData.blockchain': {
      handler (newVal) {
        this.selectedBlockchain = this.signData.blockchain
      },
      immediate: true
    },
    'signData.currency': {
      handler () {
        this.selectedCurrency = this.signData.currency
      },
      immediate: true
    },
    'signData.wallet': {
      handler () {
        this.selectedWallet = this.signData.wallet
      },
      immediate: true
    },
  },
  created () {
    this.wallets.forEach((wallet: Wallet) => this.loadBalance({
      ...(wallet.group === WalletGroup.TronLink ? { addressTron: wallet.address } : { addressEth: wallet.address })
    }))
    this.balanceLoading = false
  },
  methods: {
    ...mapActions('signProcess', {
      sign: 'sign',
      approve: 'approve',
      setWallet: 'setWallet',
      setCurrency: 'setCurrency',
      setGas: 'setGas',
      destroyStore: 'destroyStore'
    }),
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    getBlockchainIcon,
    async loadBalance ({ addressEth, addressTron }: { addressEth: string | null, addressTron: string | null }) {
      try {
        if (addressEth && !this.balances[addressEth]) {
          const balances = await getBalances({ addressEth })
          Vue.set(this.balances, addressEth, new Balances(balances))
        }
        if (addressTron && !this.balances[addressTron]) {
          const balances = await getBalances({ addressTron })
          Vue.set(this.balances, addressTron, new Balances(balances))
        }
      } catch (e) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error fetching balance. Please try again',
        })
      }
    },
    canSetGas (details: PaymentDetail) {
      return this.selectedWallet.group === WalletGroup.Cloud && details.type === PaymentDetailsTypes.Fee
    },
    setGasPrice (gasPrice: string) {
      this.setGas(gasPrice)
    },
    closeGasPrice () {
      if (this.showGasPrice) {
        this.showGasPrice = false
      }
    },
    async onWalletSelect (wallet: Wallet) {
      if (wallet) {
        try { // ToDo: add catch block
          this.walletProcessing = true
          // this.selectedWallet = wallet
          await this.setWallet(wallet)
        } finally {
          this.walletProcessing = false
        }
      }
    },
    async onChangeBlockchain (blockchain: number | { key: number }) {
      if (blockchain) {
        try { // ToDo: add catch block
          this.currencyProcessing = true
          this.selectedBlockchain = typeof blockchain === 'object' ? blockchain.key : blockchain
          const preferredCurrencies: Currency[] = this.signData.preferredCurrencies
          const currency = preferredCurrencies.find(curr => curr.blockchain === this.selectedBlockchain)
          if (currency) {
            // this.selectedCurrency = currency
            await this.setCurrency(currency)
          }
        } finally {
          this.currencyProcessing = false
        }
      }
    },
    async onCurrencySelect (currency: Currency) {
      if (currency) {
        try { // ToDo: add catch block
          this.currencyProcessing = true
          // this.selectedCurrency = currency
          await this.setCurrency(currency)
        } finally {
          this.currencyProcessing = false
        }
      }
    },
    onDepositClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/DepositCrypto/DepositCrypto.vue'),
          title: 'Deposit Crypto',
          props: {
            predefinedCurrency: this.selectedCurrency.name,
            predefinedBlockchain: this.selectedBlockchain,
            predefinedAddress: this.selectedWallet.address,
          },
        }
      })
      this.closeModal()
    },
    closeModal () {
      if (typeof this.signData.cancelCb === 'function') {
        this.signData.cancelCb()
      }
      this.$emit('close')
      this.destroyStore()
    },
  },
})
