import BigNumber from 'bignumber.js'
import { Blockchain, getChainIdByBlockchain, WalletGroup } from '@/constants/blockchain'
import Erc20Contract from './erc20-contract'
import getWeb3InstanceAsync from './web3'

/**
 * Returns the current gas price oracle
 * @param {Blockchain} blockchain
 * @param {WalletGroup} walletType
 * @returns {Promise<{ gas: string, gasPrice }>}
 */
export async function calculateFeeForTransaction (
  {
    blockchain = Blockchain.Ethereum,
    walletType = WalletGroup.Cloud,
  }
) {
  const web3 = await getWeb3InstanceAsync({ chainId: getChainIdByBlockchain(blockchain), walletGroup: walletType })
  const gasPrice = await web3.eth.getGasPrice()
  const gas = '21000'
  return { gas, gasPrice }
}

/** Calculate smart contract invoke method fee
 * @returns {Promise<Object>} Return object { gasPrice, gasLimit }
 */
export async function calculateScMethodFee (
  this: any,
  {
    chainId,
    from,
    value = '0',
    contract,
    methodName,
    methodArgs,
    gasPrice: _gasPrice,
  }: {
    chainId?: number
    from: string
    value?: string
    contract: Erc20Contract
    methodName: string
    methodArgs: any
    gasPrice?: string
  }
) {
  const web3 = await getWeb3InstanceAsync({ chainId })
  const gasPrice = !_gasPrice ? await web3.eth.getGasPrice() : _gasPrice
  // const callReturn = await contract.methods[methodName].apply(this, methodArgs).call(value !== '0'
  //   ? ({ from, value: new BigNumber(value) })
  //   : ({ from })) // Uncomment for debug and see revert error string
  const gasLimit = await contract.methods[methodName].apply(this, methodArgs).estimateGas(value !== '0' ? ({
    from,
    value: new BigNumber(value),
  }) : ({ from }))
  const feeWei = new BigNumber(gasPrice).multipliedBy(gasLimit).toString()
  return {
    gasPrice,
    gasLimit,
    feeWei,
  }
}
