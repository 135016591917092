import BigNumber from 'bignumber.js'
import Deferred from 'promise-deferred'
import Vue, { PropType } from 'vue'
import uniqueId from 'lodash/uniqueId'
import Currency from '@/models-ts/Currency'
import {
  Blockchain, SWAP_GAS_LIMIT_FIXED,
  SWAP_TOKENS_GAS_LIMIT_MULTIPLIER,
  SWAP_TOKENS_GAS_PRICE_MULTIPLIER,
  WalletGroup
} from '@/constants/blockchain'
import { awaitTxBlockchain, sendTransaction1Inch } from '@/servicies/blockchain/common'
import snackMixin from '@/mixins/snackMixin'
import { formSwapTransaction } from '@/api/1inch'
import { getTxLink } from '@/utils/etherscan'
import { formatCurrency } from '@/utils/moneyFormat'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'

export default Vue.extend<any, any, any, any>({
  name: 'lx-swap-confirm',
  mixins: [snackMixin],
  props: {
    walletGroup: Number as PropType<WalletGroup>,
    network: Number,
    blockchain: Number as PropType<Blockchain>,
    fromCurrency: Currency,
    toCurrency: Currency,
    fromAmount: String,
    fromAddress: String,
    successCb: Function,
    errorCb: Function,
    quotaGasPrice: String,
    quotaGasLimit: String,
  },
  computed: {
    networkFeeFormatted () {
      if (!this.gasPrice || !this.gasLimit) return null
      const fees = new BigNumber(this.gasPrice)
        .multipliedBy(this.gasLimit)
        .dividedBy(10 ** 18)
        .toFixed(6)
      return `${fees} ${this.fromCurrency?.baseCurrency?.name}`
    },
    fromAmountFormatted () {
      return this.fromAmount
        ? formatCurrency(this.fromAmount,
          {
            currency: this.fromCurrency,
            blockchain: this.blockchain,
            divider: this.fromCurrency.baseUnits
          })
        : 'N/A'
    },
    toAmountFormatted () {
      return this.toAmount
        ? formatCurrency(
          this.toAmount,
          {
            currency: this.toCurrency,
            blockchain: this.blockchain,
            divider: this.toCurrency.baseUnits
          }
        )
        : 'N/A'
    },
  },
  data () {
    return {
      initialLoading: true,
      swapping: false,
      buildTxError: false,
      swapTxError: false,
      errorMessage: '',
      swapTx: null,
      toAmount: null,
      gasPrice: null,
      gasLimit: null,
      tooltipFeeText: `Estimation is approximate. In order to prevent the transaction from failing,
                we take the gas limit with a margin,
                multiplying the facilitated value by a factor of ${SWAP_TOKENS_GAS_LIMIT_MULTIPLIER}.
                We also multiply the gas price by ${SWAP_TOKENS_GAS_PRICE_MULTIPLIER} to make the
                transaction faster`
    }
  },
  async created () {
    try {
      const resultSwap = await formSwapTransaction({
        network: this.network,
        fromTokenAddress: this.fromCurrency.address1Inch,
        toTokenAddress: this.toCurrency.address1Inch,
        fromAddress: this.fromAddress,
        amount: this.fromAmount,
        gasPrice: this.quotaGasPrice,
        gasLimit: SWAP_GAS_LIMIT_FIXED
      })
      this.swapTx = resultSwap?.data?.tx
      this.toAmount = resultSwap?.data?.dstAmount
      this.gasLimit = Math.floor(
        new BigNumber(this.swapTx?.gas).multipliedBy(SWAP_TOKENS_GAS_LIMIT_MULTIPLIER).toNumber()
      )
      this.gasPrice = this.swapTx?.gasPrice
    } catch (err: any) {
      console.error(err)
      this.buildTxError = true
      this.errorMessage = err?.response?.data?.message || ''
    } finally {
      this.initialLoading = false
    }
  },
  methods: {
    async onSwapClick () {
      try {
        this.swapping = true
        const txData: any = await sendTransaction1Inch({
          ...this.swapTx,
          walletGroup: this.walletGroup,
          chainId: this.network,
          gasPrice: this.gasPrice,
          gas: this.gasLimit,
        })
        const deferred = new Deferred()
        txData.eventEmitter
          .on('transactionHash', (hash: string) => {
            deferred.resolve(hash)
          })
          .on('error', (err: any) => {
            console.error(err)
            deferred.reject(err)
          })
        const tx = await deferred.promise
        const snackbarId = uniqueId()
        const link = getTxLink({ chainId: this.network, tx })
        this.openSnackbar({
          id: snackbarId,
          type: this.SnackTypes.LOADING,
          text: `Waiting for <a href="${link}" target="_blank" rel="noopener nofollow" style="text-decoration:none;">
transaction</a> confirmation from the network`,
        })
        await awaitTxBlockchain({
          chainId: this.network,
          txId: tx,
        })
        this.closeSnackbar(snackbarId)
        this.openSnackbar({
          type: this.SnackTypes.SUCCESS,
          text: 'Your transaction was successful.',
        })
        this.successCb(tx)
      } catch (err: any) {
        console.error(err)
        this.swapTxError = true
        this.errorMessage = err?.response?.data?.message || ''
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Your transaction has failed. Please try again.',
        })
        this.errorCb()
        const errMessage = err?.response?.data ? JSON.stringify(err.response.data) : err?.message
        lxAnalytics.send('swap-fail-1inch-req', { type: 'swap', errMessage })
      } finally {
        this.swapping = false
        this.$emit('close')
      }
    }
  },
})
