import BigNumber from 'bignumber.js'
import Deferred from 'promise-deferred'
import Vue, { PropType } from 'vue'
import debounce from 'lodash/debounce'
import uniqueId from 'lodash/uniqueId'
import { mapActions, mapState } from 'vuex'
import Currency from '@/models-ts/Currency'
import Wallet from '@/models-ts/Wallet'
import SwapConfirm from '@/modals/SwapConfirmNew/SwapConfirm.vue'
import getWeb3InstanceAsync from '@/servicies-ts/blockchain/web3'
import {
  BLOCKCHAIN_OPTIONS,
  Blockchain,
  BlockchainNames,
  NETWORK_IDS_FOR_SWAP,
  SWAP_TOKENS_GAS_LIMIT_MULTIPLIER,
  SWAP_TOKENS_GAS_PRICE_MULTIPLIER,
  SWAP_GAS_LIMIT_FIXED,
  WalletGroup,
  getNameByWalletGroup,
  getBlockchainNameByChainId,
} from '@/constants/blockchain'
import { CURRENCIES } from '@/constants/currency'
import {
  WALLET_GROUP_CLOUD,
  WALLET_GROUP_METAMASK,
  WALLET_GROUP_WALLET_CONNECT,
} from '@/constants/blockchain/wallet'
import { SELECT_WHITE } from '@/constants/components/select'
import { awaitTxBlockchain } from '@/servicies/blockchain/common'
import snackMixin from '@/mixins/snackMixin'
import {
  eventEmitter as eventEmitterMetamask,
  getAddress as getAddressMetamask,
  getChainId as getChainIdMetamask
} from '@/servicies/blockchain/metamask'
import {
  eventEmitter as eventEmitterWalletConnect,
  getAddress as getAddressWalletConnect,
  getChainIdInWallet as getChainIdWalletConnect,
  getProviderAsync, resetProvider
} from '@/servicies/blockchain/walletConnect'
import { formatCurrency } from '@/utils/moneyFormat'
import { getApproveSpender } from '@/api/1inch'
import { getErc20ContractAsync as getErc20ContractAsyncTs, MethodMode } from '@/servicies-ts/blockchain/erc20-contract'
import { MAX_AMOUNT_APPROVE } from '@/constants/blockchain/erc20'
import { getTxLink } from '@/utils/etherscan'
import { txSend } from '@/servicies-ts/blockchain/tx-sender'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { convertToUsd } from '@/utils-ts/currencies'
import ratesMixin from '@/mixins/ratesMixin'
import { getBalances } from '@/utils-ts/balance'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'

type AvailableBlockchain = Blockchain.Ethereum |
  Blockchain.Binance |
  Blockchain.Polygon |
  Blockchain.Fantom |
  Blockchain.Arbitrum |
  Blockchain.Base

type DefFromToCurrencies = Record<AvailableBlockchain, { from: Currency['name'], to: Currency['name'] }>

const DEF_FROM_TO_CURRENCIES: DefFromToCurrencies = {
  [Blockchain.Ethereum]: { from: 'ETH', to: 'USDT' },
  [Blockchain.Binance]: { from: 'BNB', to: 'BUSD' },
  [Blockchain.Polygon]: { from: 'MATIC', to: 'USDT' },
  [Blockchain.Fantom]: { from: 'FTM', to: 'USDC' },
  [Blockchain.Arbitrum]: { from: 'ETH', to: 'USDT' },
  [Blockchain.Base]: { from: 'ETH', to: 'USDC' },
}

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin, snackMixin],
  name: 'lx-swap',
  props: {
    predefinedBlockchain: {
      type: Number as PropType<AvailableBlockchain>
    },
    predefinedWallet: String,
  },
  data () {
    return {
      initializedFirstSetBlockchain: false,
      initialLoading: true,
      estimating: false,
      approving: false,
      swapping: false,
      wallet: null,
      fromAmount: 0,
      toAmountBaseUnits: null,
      oneInchError: false,
      fromCurrency: CURRENCIES.find(item => item.name === 'BNB'),
      toCurrency: CURRENCIES.find(item => item.name === 'BUSD'),
      blockchain: BLOCKCHAIN_OPTIONS
        .find(item => item.value === Blockchain.Binance),
      approveAddresses: {},
      listenerChainId: null,
      listenerAddress: null,
      listenerConnect: null,
      listenerDisconnect: null,
      selectedChainId: null,
      selectedAddress: null,
      isMetamaskConnected: false,
      isWalletConnectConnected: false,
      allowance: null,
      gasPrice: null,
      gasLimitApprove: null,
      gasLimitSwap: null,
      balances: null,
      swapTx: null,
      fromAmountBeforeBlur: null,
      fromAmountFocus: false,
      WALLET_GROUP_CLOUD,
      WALLET_GROUP_METAMASK,
      WALLET_GROUP_WALLET_CONNECT,
      blockchainOptions: BLOCKCHAIN_OPTIONS.filter(b => b.value !== Blockchain.Tron),
      BLOCKCHAIN_OPTIONS,
      SELECT_WHITE,
      SWAP_TOKENS_GAS_LIMIT_MULTIPLIER,
      SWAP_TOKENS_GAS_PRICE_MULTIPLIER,
      tooltipFeeText: `Estimation is approximate. In order to prevent the transaction from failing,
                we take the gas limit with a margin,
                multiplying the facilitated value by a factor of ${SWAP_TOKENS_GAS_LIMIT_MULTIPLIER}.
                We also multiply the gas price by ${SWAP_TOKENS_GAS_PRICE_MULTIPLIER} to make the
                transaction faster`
    }
  },
  computed: {
    ...mapState('user', {
      wallets: (state: any) => state.wallets,
      defaultWallet: (state: any) => state.wallet,
    }),
    walletOptions () {
      return (this.wallets || []).filter((wallet: Wallet) => wallet.group !== WalletGroup.TronLink)
    },
    insufficientBalanceToFee () {
      const balanceBase = new BigNumber(this.balances?.[this.blockchain.value]?.[this.fromCurrency.baseCurrency.name])
      if (this.gasLimitSwap && this.gasPrice) {
        const feeBase = new BigNumber(this.gasLimitSwap).multipliedBy(this.gasPrice)
        return feeBase.isGreaterThan(balanceBase)
      }
    },
    insufficientBalanceToApprove () {
      if (this.hasApprove) {
        const balanceBase = new BigNumber(this.balances?.[this.blockchain.value]?.[this.fromCurrency.baseCurrency.name])
        if (this.gasLimitApprove && this.gasPrice) {
          const feeBase = new BigNumber(this.gasLimitApprove).multipliedBy(this.gasPrice)
          return feeBase.isGreaterThan(balanceBase)
        }
      }
    },
    insufficientBalanceBase () {
      const balanceBase = new BigNumber(this.balances?.[this.blockchain.value]?.[this.fromCurrency.baseCurrency.name])
      const fromAmountBase = new BigNumber(this.fromAmount).multipliedBy(this.fromCurrency.baseUnits)
      if (this.fromCurrency.isBaseCurrency) {
        // Fee with base amount
        if (this.gasLimitSwap && this.gasPrice) {
          const feeBase = new BigNumber(this.gasLimitSwap).multipliedBy(this.gasPrice)
          return (feeBase.plus(fromAmountBase)).isGreaterThan(balanceBase)
        } else {
          return fromAmountBase.isGreaterThan(balanceBase)
        }
      } else {
        // Only fee
        if (this.hasApprove) {
          if (this.gasLimitApprove && this.gasPrice) {
            const feeBase = new BigNumber(this.gasLimitApprove).multipliedBy(this.gasPrice)
            return feeBase.isGreaterThan(balanceBase)
          }
        } else {
          if (this.gasLimitSwap && this.gasPrice) {
            const feeBase = new BigNumber(this.gasLimitSwap).multipliedBy(this.gasPrice)
            return feeBase.isGreaterThan(balanceBase)
          }
        }
      }
      return false
    },
    insufficientBalanceERC20 () {
      if (!this.fromCurrency.isBaseCurrency) {
        const fromBalanceBase = new BigNumber(this.balances?.[this.blockchain.value]?.[this.fromCurrency.name])
        const fromAmountBase = new BigNumber(this.fromAmount).multipliedBy(this.fromCurrency.baseUnits)
        if (fromBalanceBase.isLessThan(fromAmountBase)) return true
      }
      return false
    },
    fromAmountErrorMsg () {
      return this.errors.first('from-amount') ||
        (this.insufficientBalance ? 'You don’t have enough currency amount for this swap' : '')
    },
    chainId () {
      return +(NETWORK_IDS_FOR_SWAP as any)[this.blockchain.value]
    },
    fieldsDisabled () {
      return this.estimating || this.approving
    },
    currencies () {
      return CURRENCIES
        .filter((currency: Currency) => currency.blockchain === this.blockchain.value)
        .sort((a: Currency, b: Currency) => {
          const balanceA = this.balancesUsdFormatted[a.name]
          const balanceB = this.balancesUsdFormatted[b.name]
          if (new BigNumber(balanceA).isLessThan(balanceB)) {
            return 1
          } else if (new BigNumber(balanceA).isGreaterThan(balanceB)) {
            return -1
          }
          return 0
        })
    },
    balancesFormated () {
      return CURRENCIES
        .filter(currency => currency.blockchain === this.blockchain.value)
        .reduce((map: any, currency) => {
          const balance = new BigNumber(this.balances[this.blockchain.value][currency.name])
          map[currency.name] = formatCurrency(balance, { currency, divider: currency.decimalsDivider })
          return map
        }, {})
    },
    balancesUsdFormatted () {
      return CURRENCIES
        .filter(currency => currency.blockchain === this.blockchain.value)
        .reduce((map: any, currency) => {
          const balance = new BigNumber(this.balances[this.blockchain.value][currency.name])
          const balanceFormatted = formatCurrency(balance, { currency, divider: currency.decimalsDivider })
          const balanceAUsd = convertToUsd({
            value: balanceFormatted,
            currency,
            rates: this.rates,
            format: true
          })
          map[currency.name] = balanceAUsd
          return map
        }, {})
    },
    btnSwapDisabled () {
      return this.estimating ||
        this.hasApprove ||
        this.fromAmountFocus ||
        this.isNetworkWrong ||
        this.isAddressWrong ||
        this.fromAmount === 0 ||
        this.insufficientBalanceBase ||
        this.insufficientBalanceERC20
    },
    insufficientBalance () {
      return this.insufficientBalanceBase || this.insufficientBalanceERC20
    },
    btnApproveDisabled () {
      return this.insufficientBalanceBase
    },
    btnMaxAmountDisabled () {
      return new BigNumber(this.balances?.[this.blockchain.value]?.[this.fromCurrency.name] || 0).isEqualTo(0)
    },
    isMetamaskSelected () {
      return this.wallet?.group === WALLET_GROUP_METAMASK
    },
    isWalletConnectSelected () {
      return this.wallet?.group === WALLET_GROUP_WALLET_CONNECT
    },
    isNetworkWrong () {
      return ((this.isWalletConnectSelected && this.isWalletConnectConnected) ||
          (this.isMetamaskSelected && this.isMetamaskConnected)) &&
        !!this.selectedChainId &&
        this.selectedChainId !== this.chainId
    },
    isAddressWrong () {
      return ((this.isWalletConnectSelected && this.isWalletConnectConnected) ||
          (this.isMetamaskSelected && this.isMetamaskConnected)) &&
        !!this.selectedAddress &&
        this.wallet.address !== this.selectedAddress
    },
    blockchainSelectErrorMsg () {
      return this.isNetworkWrong
        // eslint-disable-next-line max-len
        ? `Selected network ${getBlockchainNameByChainId(this.selectedChainId)} in Metamask does not match current ${getBlockchainNameByChainId(this.chainId)}`
        : null
    },
    walletSelectErrorMsg () {
      return this.isAddressWrong
        ? `Selected address ${this.clippedAddress(this.selectedAddress)}
        in Metamask does not match current ${this.clippedAddress(this.wallet.address)}`
        : null
    },
    showConnectBtn () {
      return (this.isMetamaskSelected && !this.isMetamaskConnected) ||
        (this.isWalletConnectSelected && !this.isWalletConnectConnected)
    },
    textConnectBtn () {
      if (!this.showConnectBtn) return ''
      if (this.isMetamaskSelected) return 'Connect to Metamask'
      if (this.isWalletConnectSelected) return 'Connect to WalletConnect'
      return ''
    },
    swapFeeFormatted () {
      if (!this.gasPrice || !this.gasLimitSwap) return null
      const amount = new BigNumber(this.gasPrice).multipliedBy(this.gasLimitSwap)
      return this.getFormattedAmount(amount)
    },
    approveFeeFormatted () {
      if (!this.gasPrice || !this.gasLimitApprove) return null
      const amount = new BigNumber(this.gasPrice).multipliedBy(this.gasLimitApprove)
      return this.getFormattedAmount(amount)
    },
    toAmountFormatted () {
      return this.toAmountBaseUnits ? formatCurrency(this.toAmountBaseUnits,
        {
          currency: this.toCurrency,
          blockchain: this.blockchain.value,
          divider: this.toCurrency.baseUnits
        }) + ` ${this.toCurrency.name}`
        : '0.00'
    },
    fromAmountBaseBN () {
      return new BigNumber(this.fromAmount)
        .multipliedBy(this.fromCurrency.baseUnits)
    },
    hasFromAmount () {
      return new BigNumber(this.fromAmount || 0).isGreaterThan(0)
    },
    fromAmountUsd () {
      return convertToUsd({
        value: new BigNumber(this.fromAmount || 0),
        currency: this.fromCurrency,
        rates: this.rates
      })
    },
    fromToCourse () {
      const fromRate = this.rates.find((rate: any) => rate.currency === this.fromCurrency.name)?.price
      const toRate = this.rates.find((rate: any) => rate.currency === this.toCurrency.name)?.price
      if (fromRate && toRate) {
        const rate = new BigNumber(fromRate).dividedBy(toRate)
        return formatCurrency(rate, { currency: this.fromCurrency })
      }
      return '0.00'
    },
    hasApprove () {
      return !this.fromCurrency.isBaseCurrency &&
        !this.estimating &&
        (new BigNumber(this.fromAmount).multipliedBy(this.fromCurrency.baseUnits)).isGreaterThan(this.allowance || 0)
    },
    approveSpender () {
      return this.approveAddresses[this.blockchain.value]
    },
  },
  watch: {
    wallets: {
      handler () {
        this.initBlockchain()
        this.initWallet()
        this.loadBalances()
      },
      immediate: true,
    }
  },
  async created () {
    this.initBlockchain()
    await this.loadApproveSpender()
    this.initWallet()
    this.initialLoading = false
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    recalculateParams: debounce(async function (this: any) {
      try {
        await this.loadApproveSpender()
        if (!this.fromCurrency.isBaseCurrency) {
          await this.loadAllowance()
          await this.calculateApproveWithMultipliers()
        } else {
          await this.loadGasPrice()
        }
      } catch (err: any) {
        console.error(err)
        this.toAmountBaseUnits = null
        this.toAmountBaseUnits = null
        const text = err?.response?.data?.message || 'Estimate transaction failure'
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text,
        })
      } finally {
        this.estimating = false
      }
    }, 500),
    initWallet () {
      if (this.wallets && this.wallets.length > 0 && this.wallet === null) {
        if (this.predefinedWallet) {
          this.wallet = this.wallets.find((item: Wallet) => item.address === this.predefinedWallet)
        } else {
          this.wallet = this.wallets.find((item: Wallet) => item.is_default === 1)
        }
        if (!this.wallet || this.wallet.group === WalletGroup.TronLink) {
          this.wallet = this.wallets.find((item: Wallet) => item.group === WalletGroup.Cloud)
        }
        if ([WALLET_GROUP_METAMASK, WALLET_GROUP_WALLET_CONNECT].includes(this.wallet?.group)) {
          this.connectToWallet(this.wallet.group)
        }
        this.loadBalances()
      }
    },
    async initBlockchain () {
      if (!this.predefinedBlockchain || this.initializedFirstSetBlockchain || this.predefinedBlockchain === Blockchain.Tron) return
      this.blockchain = BLOCKCHAIN_OPTIONS.find(item => item.value === this.predefinedBlockchain)
      this.setFromToCurrencies(this.blockchain.value)
      await this.loadGasPrice()
      this.initializedFirstSetBlockchain = true
    },
    async loadApproveSpender () {
      try {
        this.$set(this.approveAddresses, this.blockchain.value, (await getApproveSpender(this.chainId))?.data?.address)
      } catch (err) {
        console.error(err)
        this.oneInchError = true
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Load spender address from 1inch or healthcheck failure',
        })
        const errMessage = err?.response?.data ? JSON.stringify(err.response.data) : err?.message
        lxAnalytics.send('swap-fail-1inch-req', { type: 'approve', errMessage })
      }
    },
    async loadGasPrice () {
      const web3 = await getWeb3InstanceAsync({ chainId: this.chainId })
      this.gasPrice = new BigNumber(await web3.eth.getGasPrice())
        .multipliedBy(SWAP_TOKENS_GAS_PRICE_MULTIPLIER)
        .toFixed(0)
    },
    async loadAllowance () {
      const contract = await getErc20ContractAsyncTs({
        chainId: this.chainId,
        address: this.fromCurrency.address,
      })
      this.allowance = await contract.allowance(this.wallet.address, this.approveSpender)
    },
    async loadBalances () {
      this.balances = await getBalances({ addressEth: this.wallet.address })
    },
    async calculateApproveWithMultipliers () {
      const contract = await getErc20ContractAsyncTs({
        chainId: this.chainId,
        address: this.fromCurrency.address,
      })
      const gasLimit = await contract.approve({
        mode: MethodMode.ESTIMATE_GAS,
        from: this.wallet?.address,
        spender: this.approveSpender,
        amount: MAX_AMOUNT_APPROVE
      })
      const web3 = await getWeb3InstanceAsync({ chainId: this.chainId })
      const gasPrice = await web3.eth.getGasPrice()
      this.gasLimitApprove = Math.floor(
        new BigNumber(gasLimit).multipliedBy(SWAP_TOKENS_GAS_LIMIT_MULTIPLIER).toNumber()
      )
      this.gasPrice = new BigNumber(gasPrice)
        .multipliedBy(SWAP_TOKENS_GAS_PRICE_MULTIPLIER)
        .toFixed(0)
    },
    async onConnectClick () {
      this.connectToWallet(this.wallet.group)
    },
    async connectToWallet (group = WALLET_GROUP_METAMASK) {
      eventEmitterMetamask.removeAllListeners('accountsChanged')
      eventEmitterMetamask.removeAllListeners('chainChanged')
      eventEmitterMetamask.removeAllListeners('connect')
      eventEmitterMetamask.removeAllListeners('disconnect')
      eventEmitterMetamask.removeAllListeners('start')
      eventEmitterMetamask.removeAllListeners('stop')
      if (group === WALLET_GROUP_METAMASK) {
        this.isMetamaskConnected = !!await getAddressMetamask({})
        const connectListener = () => {
          this.isMetamaskConnected = true
        }
        const disconnectListener = () => {
          this.isMetamaskConnected = false
        }
        const addressListener = (accounts: Array<string>) => {
          this.selectedAddress = accounts[0]
        }
        const chainIdListener = (chainId: number) => {
          this.selectedChainId = chainId
        }
        const metamaskChainId = await getChainIdMetamask()
        const metamaskAddress = await getAddressMetamask({})
        this.selectedChainId = metamaskChainId
        this.selectedAddress = metamaskAddress
        this.listenerAddress = addressListener
        this.listenerChainId = chainIdListener
        eventEmitterMetamask.on('accountsChanged', addressListener)
        eventEmitterMetamask.on('chainChanged', chainIdListener)
        eventEmitterMetamask.on('connect', connectListener)
        eventEmitterMetamask.on('disconnect', disconnectListener)
      }
      if (group === WALLET_GROUP_WALLET_CONNECT) {
        try {
          const provider = await getProviderAsync()
          await provider.enable()
          this.isWalletConnectConnected = true
          const connectListener = () => {
            this.isWalletConnectConnected = true
          }
          const disconnectListener = () => {
            this.isWalletConnectConnected = false
          }
          const addressListener = (accounts: Array<string>) => {
            this.selectedAddress = accounts[0]
          }
          const chainIdListener = (chainId: number) => {
            this.selectedChainId = chainId
          }
          const walletConnectAddress = await getAddressWalletConnect()
          const walletConnectChainId = await getChainIdWalletConnect()
          this.selectedChainId = walletConnectChainId
          this.selectedAddress = walletConnectAddress
          this.listenerAddress = addressListener
          this.listenerChainId = chainIdListener
          eventEmitterWalletConnect.on('accountsChanged', addressListener)
          eventEmitterWalletConnect.on('chainChanged', chainIdListener)
          eventEmitterWalletConnect.on('connect', connectListener)
          eventEmitterWalletConnect.on('disconnect', disconnectListener)
          eventEmitterWalletConnect.on('start', connectListener)
          eventEmitterWalletConnect.on('stop', disconnectListener)
        } catch (err) {
          console.error(err)
          resetProvider()
        }
      }
    },
    async onApproveClick () {
      this.approving = true
      try {
        const contract = await getErc20ContractAsyncTs({
          chainId: this.chainId,
          address: this.fromCurrency.address,
        })
        const encodedAbi = contract.approve({
          mode: MethodMode.ENCODE_ABI,
          from: this.wallet?.address,
          spender: this.approveSpender,
          amount: MAX_AMOUNT_APPROVE
        }) as string
        const { eventEmitter } = await txSend({
          walletGroup: this.wallet.group || WalletGroup.Metamask,
          chainId: this.chainId,
          from: this.wallet.address || '',
          to: this.fromCurrency.address,
          value: '0',
          encodedAbi,
          gasPrice: this.gasPrice || '0',
          gasLimit: this.gasLimitApprove
        })
        const deferred = new Deferred()
        eventEmitter
          .on('transactionHash', (hash: string) => {
            deferred.resolve(hash)
          })
          .on('error', (err: Error) => {
            console.error(err)
            deferred.reject(err)
          })
        const tx = await deferred.promise
        const link = getTxLink({ chainId: this.chainId, tx })
        const snackbarId = uniqueId()
        this.openSnackbar({
          id: snackbarId,
          type: this.SnackTypes.LOADING,
          text: `Waiting for <a href="${link}" target="_blank" rel="noopener nofollow" style="text-decoration:none;">
transaction</a> confirmation from the network`,
        })
        await awaitTxBlockchain({
          chainId: this.chainId,
          txId: tx,
          blockConfirmations: 2,
        })
        await this.loadAllowance()
        await this.loadBalances()
        this.closeSnackbar(snackbarId)
        this.openSnackbar({
          type: this.SnackTypes.SUCCESS,
          text: 'Your transaction was successful.',
        })
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Your transaction has failed. Please try again.',
        })
      } finally {
        this.approving = false
      }
    },
    async onSwapClick () {
      this.openModal({
        component: SwapConfirm,
        props: {
          walletGroup: this.wallet.group,
          network: this.chainId,
          fromCurrency: this.fromCurrency,
          toCurrency: this.toCurrency,
          fromAmount: this.fromAmountBaseBN.toFixed(0),
          fromAddress: this.wallet.address,
          blockchain: this.blockchain.value,
          quotaGasLimit: this.gasLimitSwap,
          quotaGasPrice: this.gasPrice,
          successCb: (tx: any) => {
            this.sendSuccessWalletOperation({
              tx,
              currency: this.fromCurrency,
              blockchain: this.blockchain.value,
              walletName: getNameByWalletGroup(this.wallet.group),
              amount: this.fromAmount,
            })
            this.fromAmount = 0
            this.toAmountBaseUnits = null
            this.gasPrice = null
            this.gasLimitApprove = null
            this.gasLimitSwap = null
            this.loadBalances()
          },
          errorCb: () => {
          },
        }
      })
    },
    onChange () {
      if (this.toCurrency.name === this.fromCurrency.name) {
        // TODO ситуация когда мы выбираем одну валюту снизу потом сверху и пересчет аппрува не произошел, посмотреть
        this.toAmountBaseUnits = 0
        return
      }
      this.estimating = true
      this.swapTx = null
      this.recalculateParams()
    },
    changeBlockchain (item: typeof BLOCKCHAIN_OPTIONS[0]) {
      if (item && this.blockchain !== item) {
        this.blockchain = item
        this.setFromToCurrencies(item.value)
        this.onChange()
        this.loadBalances()
      }
    },
    setFromToCurrencies (blockchain: AvailableBlockchain) {
      this.fromCurrency = CURRENCIES.find(item => {
        return item.name === DEF_FROM_TO_CURRENCIES[blockchain].from &&
          item.blockchain === blockchain
      })
      this.toCurrency = CURRENCIES.find(item => {
        return item.name === DEF_FROM_TO_CURRENCIES[blockchain].to &&
          item.blockchain === blockchain
      })
    },
    changeWallet (wallet: Wallet) {
      if (wallet) {
        this.wallet = wallet
        this.loadBalances()
        if (this.toCurrency.name !== this.fromCurrency.name) {
          this.onChange()
        }
      }
    },
    onFromAmountInput (val: number) {
      if (val !== this.fromAmount) {
        this.onChange()
      }
    },
    onFocusFromAmount () {
      this.fromAmountFocus = true
      this.fromAmountBeforeBlur = this.fromAmount
    },
    onBlurFromAmount () {
      if (this.fromAmountBeforeBlur !== this.fromAmount) {
        this.onChange()
      }
      this.fromAmountFocus = false
    },
    onFromCurrencyInput (val: Currency) {
      if (val) {
        this.fromCurrency = val
        this.onChange()
      }
    },
    onToCurrencyInput (val: Currency) {
      if (val) {
        this.toCurrency = val
        this.onChange()
      }
    },
    maxAmount () {
      if (!this.fieldsDisabled && !this.btnMaxAmountDisabled) {
        if (!this.fromCurrency.isBaseCurrency) {
          // ERC20
          if (new BigNumber(this.balances[this.blockchain.value][this.fromCurrency.name]).isLessThanOrEqualTo(0)) {
            this.openSnackbar({
              type: this.SnackTypes.FAILURE,
              text: 'Insufficient funds',
            })
            return
          }
          this.fromAmount = new BigNumber(this.balances[this.blockchain.value][this.fromCurrency.name])
            .dividedBy(this.fromCurrency.baseUnits)
            .toFixed(this.fromCurrency.maxDecimals, 1)
          this.onChange()
        } else {
          // ETH or BNB
          const balance = new BigNumber(this.balances[this.blockchain.value][this.fromCurrency.name])
          const fee = new BigNumber(this.gasPrice).multipliedBy(SWAP_GAS_LIMIT_FIXED)
          if (fee.isGreaterThan(balance)) {
            this.openSnackbar({
              type: this.SnackTypes.FAILURE,
              text: 'Insufficient funds. Please top up your account balance.',
            })
            return
          }
          this.fromAmount = balance.minus(fee).dividedBy(this.fromCurrency.baseUnits).toNumber()
          this.onChange()
        }
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    getFormattedAmount (amount: BigNumber) {
      const currency = this.currencies.find((c: Currency) => c.isBaseCurrency)
      return {
        amount: formatCurrency(amount,
          {
            currency,
            blockchain: this.blockchain.value,
            divider: currency.decimalsDivider
          }
        ),
        currency: currency.name,
        usdAmount: convertToUsd({
          value: amount.dividedBy(currency.decimalsDivider),
          currency,
          rates: this.rates,
        })
      }
    },
    sendSuccessWalletOperation ({ tx, currency, blockchain, walletName, amount }: any) {
      googleAnalyticsV2.send({
        event: 'wallet-swap',
        'event-content': tx,
        // @ts-ignore-next-line
        block_type: BlockchainNames[blockchain],
        currency: currency.name,
        sum: convertToUsd({
          value: amount,
          currency,
          rates: this.rates,
        }),
        wallet_integration_type: walletName,
      })
    },
    clippedAddress (address: string) {
      return address.slice(0, 4) + '...' + address.slice(-4)
    }
  },
})
