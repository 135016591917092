var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lx-modal-new',{staticClass:"root deposit-crypto lx-modal-medium"},[_c('h2',{staticClass:"modal-header"},[_c('span',[_vm._v("Deposit Crypto")])]),_c('section',{staticClass:"selection-section wallet-selection"},[_c('lx-select-newest',{staticClass:"select",attrs:{"placeholder":"Wallet","selectLabel":"Wallet","label":"name","track-by":"address","native":"","defaultOption":"","type":_vm.SELECT_WHITE,"value":_vm.selectedWallet,"options":_vm.wallets},on:{"input":_vm.onWalletChange},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"select-option"},[_c('div',{staticClass:"image-wrapper"},[(option.group === _vm.WALLET_GROUP_CLOUD)?_c('lx-icon',{staticClass:"icon",attrs:{"icon":"cloud","size":"18"}}):_c('img',{staticClass:"img",attrs:{"src":_vm.getWalletIcon(option)}})],1),_c('div',{staticClass:"option-name"},[_vm._v(_vm._s(option.name))])])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"select-option"},[_c('div',{staticClass:"image-wrapper"},[(option.group === _vm.WALLET_GROUP_CLOUD)?_c('lx-icon',{staticClass:"icon",attrs:{"icon":"cloud","size":"18"}}):_c('img',{staticClass:"img",attrs:{"src":_vm.getWalletIcon(option)}})],1),_c('div',{staticClass:"option-name"},[_vm._v(_vm._s(option.name))])])]}}])})],1),_c('section',{staticClass:"selection-section blockchain-selection"},[_c('lx-select-newest',{staticClass:"select blockchain-select",attrs:{"placeholder":"Blockchain","selectLabel":"Blockchain","label":"name","native":"","defaultOption":"","type":_vm.SELECT_WHITE,"value":_vm.selectedBlockchain,"options":_vm.blockchainOptions},on:{"input":_vm.onBlockchainInput},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return _c('div',{staticClass:"select-option"},[_c('lx-icon',{staticClass:"option-icon",attrs:{"src":_vm.getBlockchainIcon(option.key),"size":"24"}}),_c('div',{staticClass:"option-name"},[_vm._v(_vm._s(option.name))])],1)}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return _c('div',{staticClass:"select-option"},[_c('lx-icon',{staticClass:"option-icon",attrs:{"src":_vm.getBlockchainIcon(option.key),"size":"24"}}),_c('div',{staticClass:"option-name"},[_vm._v(_vm._s(option.name))])],1)}}])})],1),_c('section',{staticClass:"selection-section coin-selection"},[_c('lx-select-newest',{staticClass:"select",attrs:{"selectLabel":"Cryptocurrency","label":"name","value":_vm.selectedCurrency,"type":_vm.SELECT_WHITE,"options":_vm.currencyOptions,"openDirection":"top","native":"","defaultOption":"","sortable":""},on:{"input":_vm.onCurrencyInput},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return _c('lx-currency',{staticClass:"currency",attrs:{"currency":option}})}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return _c('lx-currency',{staticClass:"currency",attrs:{"currency":option}})}}])})],1),_c('section',{staticClass:"info-section"},[_c('div',{staticClass:"address-info"},[_c('h4',{staticClass:"section-title"},[_vm._v("Wallet address")]),_c('div',{staticClass:"address-copy"},[_c('lx-hex-address',{staticClass:"address",attrs:{"value":_vm.selectedWallet.address}}),_c('lx-copied-block',{staticClass:"action",attrs:{"text":_vm.selectedWallet.address}},[_c('lx-icon',{staticClass:"icon",attrs:{"icon":"content-copy","size":"12"}})],1),_c('lx-button-private',{staticClass:"action lx-empty-btn",on:{"click":function($event){_vm.qrCodeShow = !_vm.qrCodeShow}}},[_c('lx-icon',{staticClass:"icon",attrs:{"icon":"qr-code","size":"12"}})],1)],1)]),_c('transition',{attrs:{"name":"grow"}},[(_vm.qrCodeShow)?_c('section',{staticClass:"qr-code-section"},[_c('span',{staticClass:"qr-code-container"},[_c('lx-qr-code',{attrs:{"width":160,"value":_vm.selectedWallet.address}})],1),_c('div',{staticClass:"text"},[_vm._v("Scan this QR code to deposit")])]):_vm._e()]),_c('div',{staticClass:"currency-part"},[_c('lx-icon',{staticClass:"info-icon",attrs:{"icon":"info","size":"20"}}),_c('div',{staticClass:"info-block"},[_c('div',[_vm._v("\n          Send only\n          "),_c('a',{staticClass:"currency-link lx-link blue-link",attrs:{"href":_vm.tokenLink,"target":"_blank","rel":"nofollow noopener"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.selectedCurrency.name))])]),_vm._v("\n          to this deposit address\n        ")]),_c('div',[_vm._v("Ensure the network is "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.selectedBlockchain.name))])])])],1)],1),_c('section',{staticClass:"modal-actions"},[_c('lx-button-private',{staticClass:"btn lx-blue-btn",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Done")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }