import EventEmitter from 'events'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import { Blockchain, ChainId } from '@/constants/blockchain'
import { ProviderByBlockchain } from '@/constants/providers'

let provider = null

export let eventEmitter = new EventEmitter()

export function resetProvider () {
  provider = null
}

export async function getProviderAsync (blockchain = Blockchain.Ethereum) {
  if (!provider) {
    const chainIdBSC = ChainId[Blockchain.Binance]
    const chainIdPolygon = ChainId[Blockchain.Polygon]
    const WalletConnectProvider = (
      await import(/* webpackChunkName: "@walletconnect/web3-provider" */ '@walletconnect/web3-provider')
    ).default
    provider = new WalletConnectProvider({
      rpc: {
        [chainIdBSC]: ProviderByBlockchain[Blockchain.Binance],
        [chainIdPolygon]: ProviderByBlockchain[Blockchain.Polygon],
      }
    })
    provider.updateRpcUrl(chainIdBSC, ProviderByBlockchain[Blockchain.Binance])
    provider.updateRpcUrl(chainIdPolygon, ProviderByBlockchain[Blockchain.Polygon])
    provider.on('accountsChanged', async (accounts) => {
      if (accounts && Array.isArray(accounts) && accounts.length > 0) {
        const web3 = await getWeb3Async({})
        eventEmitter.emit(
          'accountsChanged',
          accounts.map(item => web3.utils.toChecksumAddress(item))
        )
      }
    })

    provider.on('chainChanged', (chainId) => {
      if (chainId) {
        eventEmitter.emit('chainChanged', parseInt(chainId))
      }
    })

    provider.on('connect', () => {
      eventEmitter.emit('connect')
    })

    provider.on('disconnect', () => {
      provider = null
      eventEmitter.emit('disconnect')
    })

    provider.on('start', () => {
      eventEmitter.emit('start')
    })

    provider.on('stop', () => {
      provider = null
      eventEmitter.emit('stop')
    })
    return provider
  } else {
    return provider
  }
}

export async function isConnected (blockchain = Blockchain.Ethereum) {
  if (!provider) {
    provider = await getProviderAsync(blockchain)
  }
  return provider.connected
}

export async function isConnecting (blockchain = Blockchain.Ethereum) {
  if (!provider) {
    provider = await getProviderAsync(blockchain)
  }
  return provider.isConnecting
}

export async function getAddress (blockchain = Blockchain.Ethereum) {
  if (!provider) {
    provider = await getProviderAsync(blockchain)
  }
  if (provider && provider.accounts && provider.accounts.length > 0) {
    const web3 = await getWeb3Async({})
    return web3.utils.toChecksumAddress(provider.accounts[0])
  } else {
    return null
  }
}

export async function getChainIdInWallet (blockchain = Blockchain.Ethereum) {
  if (!provider) {
    provider = await getProviderAsync(blockchain)
  }
  if (provider && provider.chainId) {
    return provider.chainId
  } else {
    return null
  }
}
