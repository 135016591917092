import { Blockchain, BlockchainNames, EVM_BLOCKCHAIN, WalletGroup } from '@/constants/blockchain'
import ratesMixin from '@/mixins/ratesMixin'
import Rate from '@/models-ts/Rate'
import { getCurrency } from '@/utils-ts/currencies'
import { formatCurrency, formatUsd } from '@/utils/moneyFormat'
import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  name: 'lx-wallet-balance',
  mixins: [ratesMixin],
  data () {
    return {
      BlockchainNames,
    }
  },
  computed: {
    ...mapState({
      wallet: (state: any) => state.user.wallet,
      balances: (state: any) => state.balances.balances.value || {},
      balancesIsLoaded: (state: any) => state.balances.balances.isLoaded,
    }),
    blockchains () {
      return this.wallet.group === WalletGroup.TronLink
        ? [Blockchain.Tron]
        : EVM_BLOCKCHAIN
    },
    walletBalances () {
      const balances = {} as { [key in string]: Array<any> }
      for (const blockchain in this.balances) {
        for (const currencyName in this.balances[blockchain]) {
          const value = this.balances[blockchain][currencyName]
          const currency = getCurrency({ value: currencyName, blockchain: +blockchain })!
          const rate = this.rates.find((item: Rate) => item.currency === currency.backendName)
          const balance = new BigNumber(value)
          const balanceUsd = balance
            .dividedBy(currency.baseUnits)
            .multipliedBy(rate ? rate.price : 1)
          if (!balances[blockchain]) {
            balances[blockchain] = []
          }
          balances[blockchain].push({
            currency,
            balanceUsd,
            value,
          })
        }
      }
      for (const blockchain in balances) {
        balances[blockchain] = balances[blockchain]
          .sort((balanceA, balanceB) => balanceB.balanceUsd.minus(balanceA.balanceUsd).toNumber())
          .reduce((arr, item, i) => !new BigNumber(item.value).isZero() || arr.length < 3 ? [...arr, item] : arr, [])
      }
      return balances
    },
    totalBalance () {
      let totalBalance = new BigNumber(0)
      for (const blockchain in this.walletBalances) {
        totalBalance = totalBalance.plus(
          // @ts-ignore
          this.walletBalances[blockchain].reduce((total, { balanceUsd }) => total.plus(balanceUsd), new BigNumber(0))
        )
      }
      return formatUsd(totalBalance)
    },
  },
  methods: {
    // formatUsd
    getBalances (blockchain: Blockchain) {
      const balance = this.walletBalances[blockchain]
      return (balance || []).map((item: any) => ({
        currency: item.currency,
        balanceUsd: formatUsd(item.balanceUsd),
        value: formatCurrency(item.value, { currency: item.currency, divider: item.currency.baseUnits }),
      }))
    },
  }
})
