import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import Currency from '@/models-ts/Currency'
import Wallet from '@/models-ts/Wallet'
import { CURRENCIES } from '@/constants/currency'
import { Blockchain, BlockchainNames, EVM_BLOCKCHAIN, WALLET_GROUP_CLOUD, WalletGroup } from '@/constants/blockchain'
import { SELECT_WHITE } from '@/constants/components/select'
import { getTokenLink } from '@/utils/etherscan'
import { getCurrency } from '@/utils/currency'
import { getBlockchainIcon, getWalletIcon } from '@/utils-ts/wallet'

type BlockchainOption = { key: Blockchain, name: String }

export default Vue.extend<any, any, any, any>({
  name: 'lx-deposit-crypto-modal-new',
  props: {
    predefinedAddress: String,
    predefinedCurrency: String,
    predefinedBlockchain: {
      type: Number as PropType<Blockchain>
    },
  },
  data () {
    return {
      CURRENCIES,
      SELECT_WHITE,
      WALLET_GROUP_CLOUD,
      selectedWallet: null,
      selectedBlockchain: null,
      selectedCurrency: null,
      qrCodeShow: false,
    }
  },
  computed: {
    ...mapState('user', {
      wallets: (state: any): Wallet[] => state.wallets,
    }),
    blockchainOptions (): BlockchainOption[] {
      if (this.selectedWallet.group === WalletGroup.TronLink) {
        return [Blockchain.Tron].map(key => ({ key, name: BlockchainNames[key] }))
      } else {
        return EVM_BLOCKCHAIN.map(key => ({
          key,
          name: BlockchainNames[key]
        }))
      }
    },
    currencyOptions () {
      return CURRENCIES.filter(curr => curr.blockchain === this.selectedBlockchain.key)
    },
    tokenLink () {
      return getTokenLink({
        blockchain: this.selectedBlockchain.key,
        address: this.selectedCurrency.address,
      })
    },
  },
  created () {
    if (this.predefinedAddress) {
      this.selectedWallet = this.wallets.find((wallet: Wallet) => wallet.address === this.predefinedAddress)
    }
    if (this.predefinedBlockchain) {
      this.selectedBlockchain = this.blockchainOptions.find((opt: BlockchainOption) => opt.key === this.predefinedBlockchain)
    } else {
    }
    if (this.predefinedCurrency) {
      this.selectedCurrency = this.currencyOptions.find((opt: Currency) => opt.name === this.predefinedCurrency)
    }
    if (!this.selectedWallet) {
      this.selectedWallet = this.wallets.find((wallet: Wallet) => wallet.is_default) || this.wallets[0]
    }
    if (!this.selectedBlockchain) {
      this.selectedBlockchain = this.blockchainOptions[0]
    }
    if (!this.selectedCurrency) {
      this.selectedCurrency = this.currencyOptions[0]
    }
  },
  methods: {
    getBlockchainIcon,
    getWalletIcon,
    isTronLink (wallet: Wallet) {
      return wallet.group === WalletGroup.TronLink
    },
    onWalletChange (wallet: Wallet | null) {
      if (wallet) {
        this.selectedWallet = wallet
        if (this.isTronLink(wallet)) {
          this.selectedBlockchain = this.blockchainOptions.find((item: BlockchainOption) => item.key === Blockchain.Tron)
          this.selectedCurrency = getCurrency({ blockchain: Blockchain.Tron, value: 'TRX' })
        } else {
          this.selectedBlockchain = this.blockchainOptions.find((item: BlockchainOption) => item.key === Blockchain.Ethereum)
          this.selectedCurrency = getCurrency({ blockchain: Blockchain.Ethereum, value: 'ETH' })
        }
      }
    },
    onBlockchainInput (blockchain: BlockchainOption) {
      if (blockchain) {
        this.selectedBlockchain = blockchain
        this.selectedCurrency = this.currencyOptions[0]
      }
    },
    onCurrencyInput (currency: Currency) {
      if (currency) {
        this.selectedCurrency = currency
      }
    }
  },
})
