import { render, staticRenderFns } from "./RenameWalletModal.htm?vue&type=template&id=3e1070c6&scoped=true&"
import script from "./RenameWalletModal.js?vue&type=script&lang=js&"
export * from "./RenameWalletModal.js?vue&type=script&lang=js&"
import style0 from "./RenameWalletModal.scss?vue&type=style&index=0&id=3e1070c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e1070c6",
  null
  
)

export default component.exports