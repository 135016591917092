import BigNumber from 'bignumber.js'
import Vue, { PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { CURRENCIES, GWEI_IN_ETH } from '@/constants/currency'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import { Blockchain } from '@/constants/blockchain'
import { ActiveProfile } from '@/models/user'

const MODE_BASIC = 1
const MODE_ADVANCED = 2

export default Vue.extend<any, any, any, any>({
  props: {
    initialGasPrice: {
      type: String,
      default: ''
    },
    blockchain: {
      type: Number as PropType<Blockchain>
    },
    disabled: Boolean,
  },
  data () {
    return {
      MODE_BASIC,
      MODE_ADVANCED,
      mode: MODE_BASIC,
      gasPrice: '0',
      optionsSlider: [
        {
          id: 1,
          name: 'Low',
        },
        {
          id: 2,
          name: 'Average',
        },
        {
          id: 3,
          name: 'High',
        },
      ],
      optionSliderSelected: 1,
      gweiForAdvancedMode: '0',
      loading: false,
    }
  },
  computed: {
    ...mapState('user', {
      userProfile: (state: any): ActiveProfile => state.profile,
    }),
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    precision () {
      return 6
    },
    baseCurrency () {
      return CURRENCIES.find(curr => curr.blockchain === this.blockchain && curr.isBaseCurrency)?.name
    },
    isModeBasic () {
      return this.mode === MODE_BASIC
    },
    isModeAdvanced () {
      return this.mode === MODE_ADVANCED
    },
    lowCoefficient () {
      return this.userProfile.isLoaded ? this.backendConfig['GASPRICE_LOW_COEFFICIENT'] : '1'
    },
    mediumCoefficient () {
      return this.userProfile.isLoaded ? this.backendConfig['GASPRICE_MEDIUM_COEFFICIENT'] : '1.2'
    },
    highCoefficient () {
      return this.userProfile.isLoaded ? this.backendConfig['GASPRICE_HIGH_COEFFICIENT'] : '1.5'
    },
    lowInGwei () {
      return new BigNumber(this.gasPrice)
        .multipliedBy(this.lowCoefficient)
        .dividedBy(GWEI_IN_ETH)
        .toFixed(this.precision)
    },
    mediumInGwei () {
      return new BigNumber(this.gasPrice)
        .multipliedBy(this.mediumCoefficient)
        .dividedBy(GWEI_IN_ETH)
        .toFixed(this.precision)
    },
    highInGwei () {
      return new BigNumber(this.gasPrice)
        .multipliedBy(this.highCoefficient)
        .dividedBy(GWEI_IN_ETH)
        .toFixed(this.precision)
    },
    gweiForBasicMode () {
      switch (this.optionSliderSelected) {
        case 1:
          return this.lowInGwei
        case 2:
          return this.mediumInGwei
        default:
          return this.highInGwei
      }
    }
  },
  watch: {
    blockchain: {
      handler () {
        this.updateGasPrice()
      },
      immediate: true,
    },
  },
  methods: {
    async updateGasPrice () {
      if (!this.initialGasPrice) {
        this.loading = true
        console.log('blockchain', this.blockchain)
        const web3 = await getWeb3Async({ blockchain: this.blockchain })
        console.log('web3', web3)
        this.gasPrice = await web3.eth.getGasPrice()
        console.log('gasPrice', this.gasPrice)
        this.gweiForAdvancedMode = this.gweiForBasicMode
        console.log('gweiForBasicMode', this.gweiForBasicMode)
        this.loading = false
      } else {
        this.gasPrice = this.initialGasPrice
        this.gweiForAdvancedMode = this.gweiForBasicMode
      }
      this.$emit('input', this.gasPrice)
    },
    onChangeMode (mode: Number | Boolean) {
      if (this.loading || this.disabled) return null
      this.mode = mode
      let wei = null
      switch (this.mode) {
        case MODE_BASIC:
          wei = new BigNumber(this.gweiForBasicMode).multipliedBy(GWEI_IN_ETH).toFixed(0)
          this.$emit('input', wei)
          break
        case MODE_ADVANCED:
          wei = new BigNumber(this.gweiForAdvancedMode).multipliedBy(GWEI_IN_ETH).toFixed(0)
          this.$emit('input', wei)
          break
      }
    },
    changeSlider (val: Number) {
      this.optionSliderSelected = val
      const wei = new BigNumber(this.gweiForBasicMode).multipliedBy(GWEI_IN_ETH).toFixed(0)
      this.$emit('input', wei)
    },
    changeGweForAdvancedMode (gwei: BigNumber.Value) {
      this.gweiForAdvancedMode = gwei
      const wei = new BigNumber(gwei).multipliedBy(GWEI_IN_ETH).toFixed(0)
      this.$emit('input', wei)
    },
  },
})
