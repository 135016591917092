import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway'
import CreateContractSignDataV2 from '@/models-ts/sign-process/CreateContractSignDataV2'
import PaymentDetail, { PaymentDetailsTypes } from '@/models-ts/sign-process/PaymentDetail'
import Wallet from '@/models-ts/Wallet'
import ratesMixin from '@/mixins/ratesMixin'
import {
  Blockchain,
  getBlockchainNameByChainId,
  getNameByWalletGroup,
  getTronArtifactsNameByNode,
  WalletGroup,
} from '@/constants/blockchain'
import { DATE_TIME_FORMAT_BY_MERIDIEM } from '@/constants/utils/date'
import { addSeconds, convertToLocal } from '@/utils/date'
import { formatCurrency } from '@/utils/moneyFormat'
import { convertToUsd } from '@/utils-ts/currencies'
import GasPriceNew from '@/partials/GasPriceNew/GasPriceNew.vue'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import WalletSelect from '@/partials/WalletSelect/WalletSelect.vue'
import WalletConnectV2 from '@/partials/WalletConnectV2/WalletConnectV2.vue'
import { ProviderByBlockchain } from '@/constants/providers'

export default Vue.extend<any, any, any, any>({
  name: 'lx-pay-to-freelancer-modal',
  mixins: [clickaway, ratesMixin],
  components: { GasPriceNew, UserInfo, WalletSelect, WalletConnectV2 },
  data () {
    return {
      showGasPrice: false,
      walletProcessing: false,
      currencyProcessing: false,
      selectedWallet: null as Wallet | null,
      WalletGroup,
    }
  },
  computed: {
    ...mapState('signProcess', {
      signData: (state: any): CreateContractSignDataV2 => state.signData,
      loading: (state: any): boolean => state.loading,
      approving: (state: any): boolean => state.approving,
      signing: (state: any): boolean => state.signing,
      isAvailable: (state: any): boolean => state.isAvailable,
      isConnected: (state: any): boolean => state.isConnected,
      chainIds: (state: any): number | null => state?.chainIds,
      addresses: (state: any): string => state.addresses,
      tronLinkNode: (state: any): string => state.tronLinkNode,
    }),
    ...mapGetters('signProcess', {
      chainId: 'chainId',
      address: 'address',
    }),
    initialLoading () {
      return !this.ratesLoaded
    },
    isGig () {
      return !!(this.signData.backendParams.gigJobId)
    },
    escrowBalance () {
      const currency = this.signData.currency
      return {
        currency,
        amountFormat: formatCurrency(this.signData.escrowBalance, {
          blockchain: this.signData.blockchain,
          currency,
        }),
        amountFormatUsd: convertToUsd({
          value: this.signData.escrowBalance,
          currency,
          rates: this.rates,
        }),
      }
    },
    amountDetails () {
      const details: PaymentDetail[] = this.signData.approve ? this.signData.approve.details : this.signData.paymentDetails
      return details.filter(item => item.type !== PaymentDetailsTypes.DepositAmount)
    },
    contractName () {
      return this.signData.name
    },
    freelancer () {
      return this.signData.freelancer
    },
    deadline () {
      return convertToLocal(addSeconds(this.signData.createdAt, this.signData.deadline), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    balanceErrors () {
      return this.signData.missingBalance
    },
    hasMissingBalanceErrors () {
      return false // !!this.balanceErrors.length
    },
    walletError () {
      const walletGroup = this.selectedWallet.group
      if ([WalletGroup.Metamask, WalletGroup.WalletConnect, WalletGroup.TronLink].includes(walletGroup)) {
        const walletType = getNameByWalletGroup(walletGroup)
        if (!this.isAvailable) {
          return `${walletType} not found or not installed`
        }
        if (!this.isConnected) {
          return `${walletType} isn't connected or accounts are unavailable`
        }
        if (WalletGroup.WalletConnect === walletGroup) {
          if (!(this.addresses || []).includes(this.signData?.wallet?.address)) {
            return `Selected (Current connected) addresses in ${walletType} doesn’t match the address provided in the contract`
          }
        } else {
          if (this.address !== this.signData?.wallet?.address) {
            return `Selected (Current connected) address in ${walletType} doesn’t match the address provided in the contract`
          }
        }
        if (WalletGroup.TronLink === walletGroup) {
          if (ProviderByBlockchain[Blockchain.Tron] !== this.tronLinkNode) {
            return `Selected tron link network does not match with ${getTronArtifactsNameByNode(this.signData?.tronLinkNode)}`
          }
        } else {
          if (WalletGroup.WalletConnect === walletGroup) {
            if (!(this.chainIds || []).includes(this.signData?.chainId)) {
              return `Selected network(s)
              in ${walletType} does not match with current ${getBlockchainNameByChainId(this.signData.chainId)}`
            }
          } else {
            if (this.chainId !== this.signData?.chainId) {
              // eslint-disable-next-line max-len
              return `Selected network ${getBlockchainNameByChainId(this.chainId)} in ${walletType} does not match with current ${getBlockchainNameByChainId(this.signData.chainId)}`
            }
          }
        }
      }
      if (this.hasMissingBalanceErrors && !this.processing) {
        const currencies = this.balanceErrors.map((err: any) => err.currency.displayName)
        return `You do not have sufficient ${currencies.join(' and ')} to cover these costs`
      }
      return ''
    },
    processing () {
      return this.walletProcessing || this.currencyProcessing || this.signing || this.approving
    },
    approveBtnDisabled () {
      return !!(this.processing || this.walletError)
    },
    signBtnDisabled () {
      return !!(this.processing || this.walletError || this.hasMissingBalanceErrors)
    },
    allFieldsDisabled (): boolean {
      return this.approving || this.signing
    },
    isWalletConnect () {
      return this.selectedWallet?.group === WalletGroup.WalletConnect
    },
  },
  watch: {
    'signData.wallet': {
      handler () {
        this.selectedWallet = this.signData.wallet
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions('signProcess', {
      sign: 'sign',
      approve: 'approve',
      setGas: 'setGas',
      destroyStore: 'destroyStore'
    }),
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    getWalletImage (wallet: Wallet) {
      switch (wallet.group) {
        case WalletGroup.Metamask:
          return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect:
          return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink:
          return '/static/images/wallets/tron-wallet.png'
        default:
          return '/static/images/wallets/cloud-wallet.svg'
      }
    },
    canSetGas (details: PaymentDetail) {
      return this.selectedWallet.group === WalletGroup.Cloud && details.type === PaymentDetailsTypes.Fee
    },
    setGasPrice (gasPrice: string) {
      this.setGas(gasPrice)
    },
    closeGasPrice () {
      if (this.showGasPrice) {
        this.showGasPrice = false
      }
    },
    onDepositClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/DepositCrypto/DepositCrypto.vue'),
          title: 'Deposit Crypto',
          props: {
            // predefinedCurrency: this.selectedCurrency.name,
            // predefinedBlockchain: this.selectedBlockchain,
            predefinedAddress: this.selectedWallet.address,
          },
        }
      })
      this.closeModal()
    },
    closeModal () {
      this.$emit('close')
      this.destroyStore()
    },
  },
})
