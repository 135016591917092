import Vue from 'vue'
import { CustomerTypes } from '@/constants/user/roles'

export default Vue.extend<any, any, any, any>({
  props: {
    isVerified: Boolean,
    customerType: Number,
  },
  computed: {
    popoverText () {
      const part = this.customerType === CustomerTypes.INDIVIDUAL
        ? 'customer'
        : 'company'
      return `This ${part} has been verified by LaborX`
    },
  }
})
