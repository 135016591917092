import { render, staticRenderFns } from "./Swap.htm?vue&type=template&id=25de0cf4&scoped=true&"
import script from "./Swap.ts?vue&type=script&lang=js&"
export * from "./Swap.ts?vue&type=script&lang=js&"
import style0 from "./Swap.scss?vue&type=style&index=0&id=25de0cf4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25de0cf4",
  null
  
)

export default component.exports