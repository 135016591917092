import { mapActions } from 'vuex'
import snackMixin from '@/mixins/snackMixin'
import { WalletGroup } from '@/constants/blockchain'
import { getWalletGroupName, getWalletIcon } from '@/utils-ts/wallet'

export default {
  name: 'lx-wallet-add-modal-new',
  mixins: [snackMixin],
  props: ['wallet'],
  data () {
    return {
      WalletGroup,
      walletName: '',
      loading: false,
    }
  },
  created () {
    this.walletName = this.wallet.name
  },
  methods: {
    getWalletGroupName,
    getWalletIcon,
    ...mapActions({
      renameWallet: 'user/renameWallet',
    }),
    async onSubmit () {
      try {
        this.loading = true
        this.errors.clear()
        if (await this.$validator.validateAll()) {
          await this.renameWallet({ address: this.wallet.address, name: this.walletName.trim() })
          this.$emit('close')
        }
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error renaming wallet. Please try again.',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
