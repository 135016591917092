import axios from 'axios'
import storeFromContext from '@/store/storeFromContext'
import { AxiosThrottler } from 'axios-throttler'
import get from 'lodash/get.js'
import NotAuthorizedError from '@/errors/NotAuthorizedError.js'

const BASE_URL = axios.create({ baseURL: process.env.VUE_APP_1INCH_API_URL })
AxiosThrottler.throttle(BASE_URL, AxiosThrottler.rps(1))

BASE_URL.interceptors.request.use(request => {
  const store = storeFromContext()
  const token = get(store, 'state.user.accessToken')
  if (!token) {
    throw new NotAuthorizedError()
  }
  request.headers['Authorization'] = `Bearer ${token}`
  return request
})

/** Method for returning spender address, will return this data in req.data
 * { "address": "0x111111125421ca6dc452d289314280a0f8842a65" }
 */
export async function getApproveSpender (network = 1, tokenAddress) {
  return BASE_URL.get(`/v6.0/${network}/approve/spender`)
}

/** Method for forming approve transaction, will return this data in req.data {
 *   "dstAmount": "753190836010280626",
 *   "tx": {
 *   "from": "0xd48e934fcb64d79712d2ef70e8795ebaf2492074",
 *     "to": "0x111111125421ca6dc452d289314280a0f8842a65",
 *     "data": "0x07ed....00000000000008b1ccac8",
 *     "value": "0",
 *     "gas": 184779,
 *     "gasPrice": "1200000000"
 * }
 */
export async function formSwapTransaction (
  {
    network = 1,
    fromTokenAddress,
    toTokenAddress,
    amount,
    fromAddress,
    slippage = 1,
    gasLimit,
    gasPrice
  }
) {
  return BASE_URL.get(
    `/v6.0/${network}/swap`,
    {
      params: {
        fromTokenAddress,
        toTokenAddress,
        amount,
        fromAddress,
        slippage,
        gasLimit: gasLimit || 1000000,
        gasPrice
      }
    }
  )
}
