import Vue from 'vue'
import { mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import get from 'lodash/get'
import { CURRENCY_FIELD_BACKEND_NAME } from '@/constants/currency'
import { OUTPUT_FORMATS } from '@/constants/utils/moneyFormat'
import { createWithdraw } from '@/api/timewarp'
import { formatCurrency } from '@/utils/moneyFormat'
import { convertToUsd } from '@/utils-ts/currencies'
import { getCurrency } from '@/utils/currency'
import { WALLET_GROUP_CLOUD, WalletGroup } from '@/constants/blockchain'
import { SELECT_WHITE } from '@/constants/components/select'
import Wallet from '@/models-ts/Wallet'
import Currency from '@/models-ts/Currency'
import ratesMixin from '@/mixins/ratesMixin'
import notifiableRequest from '@/utils/notifiableRequest'

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  props: {
    totalEarnings: BigNumber,
    currency: Currency,
    onSuccess: Function,
  },
  data () {
    return {
      WALLET_GROUP_CLOUD,
      SELECT_WHITE,
      withdrawing: false,
      selectedWallet: null,
    }
  },
  computed: {
    ...mapState('user', {
      wallets: (state: any): Wallet[] => state.wallets,
    }),
    walletsOptions () {
      return this.wallets.filter((w: Wallet) => w.group !== WalletGroup.TronLink)
    },
    earnings () {
      if (new BigNumber(this.totalEarnings).eq(0)) {
        return 0
      }
      return formatCurrency(this.totalEarnings, {
        currency: this.currency,
        outputFormat: OUTPUT_FORMATS.CURRENCY_COMISSION,
        minDecimal: 3,
        maxDecimal: 3,
      })
    },
    earningsUsd () {
      if (new BigNumber(this.totalEarnings).eq(0)) {
        return 0
      }
      const usdEarning = convertToUsd({
        value: this.totalEarnings,
        currency: this.currency,
        rates: this.rates,
      })
      return usdEarning
    },
  },
  mounted () {
    this.selectedWallet = this.walletsOptions.find((wallet: Wallet) => wallet.is_default) ||
      this.walletsOptions.find((wallet: Wallet) => wallet.group === WalletGroup.Cloud)
  },
  methods: {
    getWalletImage (wallet: Wallet) {
      switch (wallet.group) {
        case WalletGroup.Metamask:
          return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect:
          return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink:
          return '/static/images/wallets/tron-wallet.png'
        default:
          return '/static/images/wallets/cloud-wallet.svg'
      }
    },
    formatAmount (amount: number | string) {
      const currency = getCurrency({ value: 'TIME', field: CURRENCY_FIELD_BACKEND_NAME })
      return formatCurrency(amount, { currency, outputFormat: OUTPUT_FORMATS.CURRENCY_COMISSION })
    },
    async onSubmit () {
      try {
        this.withdrawing = true
        await notifiableRequest({
          // @ts-ignore
          request: () => createWithdraw({ wallet: this.selectedWallet.address }),
          title: 'Withdraw',
          successText: 'Your withdrawal request has successfully been submitted.',
          failureText: (e: Error) => get(e, 'response.data.message', 'Error creating creating a withdrawal request')
        })
        if (this.onSuccess) {
          this.onSuccess()
        }
        this.$emit('close')
      } finally {
        this.withdrawing = false
      }
    },
    onWalletChange (wallet: Wallet | null) {
      if (wallet) {
        this.selectedWallet = wallet
      }
    },
  },
})
