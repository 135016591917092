import BigNumber from 'bignumber.js'
import Deferred from 'promise-deferred'
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import Balances from '@/models/Balances'
import Button from '@/models-ts/ui/Button'
import Currency from '@/models-ts/Currency'
import GasPriceNew from '@/partials/GasPriceNew/GasPriceNew.vue'
import MetamaskProvider from '@/servicies-ts/blockchain/provider-metamask'
import PendingTx, { TypeAwait } from '@/models-ts/PendingTx'
import TronLinkProvider from '@/servicies-ts/blockchain/tron/provider-tronlink'
import Wallet from '@/models-ts/Wallet'
import WalletConnectV2 from '@/partials/WalletConnectV2/WalletConnectV2.vue'
import notifiableRequest from '@/utils/notifiableRequest'
import ratesMixin from '@/mixins/ratesMixin'
import { BUTTON_BLUE_MEDIUM } from '@/constants/components/button'
import { BASE_UNITS_IN_ETH, CURRENCIES } from '@/constants/currency'
import {
  Blockchain,
  BlockchainNames,
  ChainId,
  EVM_BLOCKCHAIN,
  WALLET_GROUP_CLOUD,
  WALLET_GROUP_METAMASK,
  WALLET_GROUP_WALLET_CONNECT,
  WalletGroup,
  getBlockchainNameByChainId,
  getNameByWalletGroup
} from '@/constants/blockchain'
import { ContractType } from '@/constants/contracts'
import { SELECT_WHITE } from '@/constants/components/select'
import { calculateFeeForTransaction } from '@/servicies-ts/blockchain/common'
import snackMixin from '@/mixins/snackMixin'
import { convertToUsd } from '@/utils-ts/currencies'
import debounce from 'lodash/debounce'
import { ethAddress } from '@/plugins/veeValidate'
import { formatCurrency, formatUsdWithZeros } from '@/utils/moneyFormat'
import { getBalanceByAddress, getBalances } from '@/utils-ts/balance'
import { getBlockchainIcon } from '@/utils-ts/wallet'
import { getErc20ContractAsync, MethodMode } from '@/servicies-ts/blockchain/erc20-contract'
import { getErc20ContractTronAsync } from '@/servicies-ts/blockchain/tron/erc20-contract'
import { getTxLink as getTxLinkTron } from '@/utils-ts/tron/tronscan'
import { getTxLink } from '@/utils-ts/transaction'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { txSend } from '@/servicies-ts/blockchain/tx-sender'
import {
  getWalletProviderConnectV2Async,
  WalletConnectProviderV2
} from '@/servicies-ts/blockchain/provider-walletconnect-v2'
import { ProviderByBlockchain } from '@/constants/providers'

type BlockchainOption = { key: Blockchain, name: String }

const GAS_MULTIPLIER = 2

export default Vue.extend<any, any, any, any>({
  name: 'lx-withdraw-crypto-modal-new',
  mixins: [ratesMixin, snackMixin],
  components: {
    GasPriceNew,
    WalletConnectV2,
  },
  props: {
    predefinedAddress: String,
    predefinedCurrency: String,
    predefinedBlockchain: {
      type: Number as PropType<Blockchain>
    },
    onSuccess: Function,
  },
  data () {
    return {
      BUTTON_BLUE_MEDIUM,
      CURRENCIES,
      SELECT_WHITE,
      WALLET_GROUP_CLOUD,
      WalletGroup,
      balances: {},
      selectedWallet: null,
      selectedBlockchain: null,
      selectedCurrency: null,
      customAddress: '',
      customCurrencies: [],
      toAddress: '',
      amount: 0,
      gas: null,
      gasPrice: null,
      fee: new BigNumber(0),
      feeCalculating: false,
      feeExceedError: false,
      maxAmountCalculating: false,
      withdrawing: false,
      withCustom: false,

      wcChainIds: null,
      wcAddresses: null,
      wcIsConnected: false,
      wcConnectListener: null,
      wcDisconnectListener: null,
      wcUpdatedItemsListener: null,
    }
  },
  computed: {
    ...mapState('user', {
      wallets: (state: any): Wallet[] => state.wallets,
    }),
    blockchainOptions (): BlockchainOption[] {
      const supportedBlockchains = this.selectedWallet.group === WalletGroup.TronLink
        ? [Blockchain.Tron]
        : EVM_BLOCKCHAIN
      return supportedBlockchains.map(key => ({
        key,
        name: BlockchainNames[key]
      }))
    },
    wcAddressesLowerCase () {
      return this.wcAddresses
        ? this.wcAddresses.map((item: string) => item.toLowerCase())
        : null
    },
    currencyOptions () {
      return [...CURRENCIES, ...this.customCurrencies]
        .filter((curr: Currency) => curr.blockchain === this.selectedBlockchain.key)
    },
    baseCurrency () {
      return this.currencyOptions.find((c: Currency) => c.isBaseCurrency)
    },
    feeUsd () {
      return formatUsdWithZeros(
        convertToUsd({
          value: this.fee,
          currency: this.baseCurrency,
          rates: this.rates,
        })
      )
    },
    amountUsd () {
      return formatUsdWithZeros(
        convertToUsd({
          value: this.amount,
          currency: this.selectedCurrency,
          rates: this.rates,
        })
      )
    },
    currentBalances () {
      if (this.balances[this.selectedWallet.address]) {
        const balances = this.balances[this.selectedWallet.address][this.selectedBlockchain.key]
        return (this.currencyOptions as Array<Currency>)
          .map(currency => {
            const balance = new BigNumber(balances[currency.name] || '0').dividedBy(currency.decimalsDivider)
            return {
              name: currency.name,
              balance,
              usd: formatUsdWithZeros(
                convertToUsd({
                  value: balance,
                  currency,
                  rates: this.rates,
                })
              ),
              formattedBalance: `${formatCurrency(balance, { currency })} ${currency.name}`
            }
          })
          .reduce((acc, { name, ...balances }) => ({ ...acc, [name]: balances }), {})
      }
      return null
    },
    addressIsValid () {
      return ethAddress.validate(this.toAddress)
    },
    amountInBaseUnits () {
      return new BigNumber(this.amount || 0).multipliedBy(this.selectedCurrency.decimalsDivider)
    },
    isNotEnoughBalance () {
      if (!this.currentBalances) return true
      const baseCurrencyBalance = this.currentBalances[this.selectedCurrency.name].balance
      if (this.selectedCurrency.isBaseCurrency) {
        const withdrawSum = new BigNumber(this.amount || 0).plus(this.fee)
        return withdrawSum.isGreaterThan(baseCurrencyBalance)
      } else {
        const withdrawSumErc20 = new BigNumber(this.amount || 0)
        const erc20Balance = this.currentBalances[this.selectedCurrency.name].balance
        return this.fee.isGreaterThan(baseCurrencyBalance) ||
          withdrawSumErc20.isGreaterThan(erc20Balance)
      }
    },
    amountGreatThanBalance () {
      if (!this.currentBalances) return false
      const baseCurrencyBalance = this.currentBalances[this.selectedCurrency.name].balance
      return baseCurrencyBalance.isLessThan(this.amount || 0)
    },
    amountError () {
      if (this.errors.has('amount')) {
        return this.errors.first('amount')
      }
      if (this.amountGreatThanBalance || this.feeExceedError) {
        return 'Please enter an amount no higher than your available balance'
      }
      return ''
    },
    confirmDisabled () {
      return this.feeCalculating ||
        !+this.amount ||
        this.maxAmountCalculating ||
        (!this.gasPrice && ![WalletGroup.TronLink, WalletGroup.WalletConnect].includes(this.selectedWallet?.group))
    },
    isWalletConnect () {
      return this.selectedWallet?.group === WalletGroup.WalletConnect
    },
    isTronSelected () {
      return this.selectedBlockchain.key === Blockchain.Tron
    },
  },
  created () {
    console.log('debug WithdrawCryptoNew')
    if (this.predefinedAddress) {
      this.selectedWallet = this.wallets.find((wallet: Wallet) => wallet.address === this.predefinedAddress)
    }
    if (this.predefinedBlockchain) {
      this.selectedBlockchain = this.blockchainOptions.find((opt: BlockchainOption) => opt.key === +this.predefinedBlockchain)
    }
    if (this.predefinedCurrency) {
      this.selectedCurrency = this.currencyOptions.find((opt: Currency) => opt.name === this.predefinedCurrency)
    }
    if (!this.selectedWallet) {
      this.selectedWallet = this.wallets.find((wallet: Wallet) => wallet.is_default) || this.wallets[0]
    }
    if (!this.selectedBlockchain) {
      this.selectedBlockchain = this.blockchainOptions[0]
    }
    if (!this.selectedCurrency) {
      this.selectedCurrency = this.currencyOptions[0]
    }
    this.wallets.forEach((wallet: Wallet) => this.loadBalance({
      ...(wallet.group === WalletGroup.TronLink ? { addressTron: wallet.address } : { addressEth: wallet.address })
    }))
    this.initAndDestroyWalletConnectIfRequired()
  },
  methods: {
    getBlockchainIcon,
    ...mapActions({
      addAndAwaitTx: 'pendingTxs/addAndAwaitTx',
      openModal: 'ui/openModal',
    }),
    initAndDestroyWalletConnectIfRequired () {
      if (this.selectedWallet.group === WalletGroup.WalletConnect) {
        this.initWalletConnect()
      } else {
        this.destroyWalletConnect()
      }
    },
    async initWalletConnect () {
      const provider: WalletConnectProviderV2 = await getWalletProviderConnectV2Async()
      this.wcIsConnected = provider.isConnected()
      if (this.wcIsConnected) {
        const walletData = await provider.getChainIdsAndAddresses()
        this.wcAddresses = walletData?.addresses
        this.wcChainIds = walletData?.chainIds
      }
      this.wcUpdatedItemsListener = ({ chainIds, addresses }: any) => {
        this.wcChainIds = chainIds
        this.wcAddresses = addresses
      }
      this.wcConnectListener = async ({ chainIds, addresses }: any) => {
        this.wcChainIds = chainIds
        this.wcAddresses = addresses
        this.wcIsConnected = true
      }
      this.wcDisconnectListener = () => {
        this.wcChainIds = null
        this.wcAddresses = null
        this.wcIsConnected = false
      }
      provider.events.on('updatedItems', this.wcUpdatedItemsListener)
      provider.events.on('connect', this.wcConnectListener)
      provider.events.on('disconnect', this.wcDisconnectListener)
    },
    async destroyWalletConnect () {
      const provider: WalletConnectProviderV2 = await getWalletProviderConnectV2Async()
      this.wcUpdatedItemsListener && provider.events.removeListener('updatedItems', this.wcUpdatedItemsListener)
      this.wcConnectListener && provider.events.removeListener('connect', this.wcConnectListener)
      this.wcDisconnectListener && provider.events.removeListener('disconnect', this.wcDisconnectListener)
      this.wcIsAvailable = false
      this.wcIsConnected = false
      this.wcChainIds = null
      this.wcAddresses = null
    },
    async onCustomInput () {
      if (this.customAddress.replace(/\s+/g, '') && await this.$validator.validate('customAddress.customAddress')) {
        let currency: Currency = this.currencyOptions.find((cur: Currency) => cur.address === this.customAddress)
        if (currency) {
          this.onCurrencyInput(currency)
        } else {
          currency = this.customCurrencies.find((curr: Currency) => curr.address === this.customAddress)
          if (!currency) {
            const contract = this.selectedBlockchain.key === Blockchain.Tron
              ? await getErc20ContractTronAsync({
                contractType: ContractType.ReadWithTronWeb,
                address: this.customAddress
              })
              : await getErc20ContractAsync({
                chainId: Number(ChainId[this.selectedBlockchain.key as Blockchain]),
                address: this.customAddress
              })
            try {
              currency = await contract.toCurrency()
              this.customCurrencies.push(currency)
              this.onCurrencyInput(currency)
              this.wallets.forEach(async (wallet: Wallet) => {
                const balance = await getBalanceByAddress({ address: wallet.address, currency })
                Vue.set(this.balances[wallet.address][currency.blockchain], currency.name, balance)
              })
            } catch (e) {
              console.error('Failed to cust %s to token', this.customAddress)
              throw e
            }
          } else {
            this.selectedBlockchain = this.blockchainOptions.find((b: BlockchainOption) => b.key === currency.blockchain)
            this.onCurrencyInput(currency)
          }
        }
      }
    },
    async loadBalance ({ addressEth, addressTron }: { addressEth: string | null, addressTron: string | null }) {
      try {
        if (addressEth && !this.balances[addressEth]) {
          const balances = await getBalances({ addressEth })
          Vue.set(this.balances, addressEth, new Balances(balances))
        }
        if (addressTron && !this.balances[addressTron]) {
          const balances = await getBalances({ addressTron })
          Vue.set(this.balances, addressTron, new Balances(balances))
        }
      } catch (e) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error fetching balance. Please try again',
        })
      }
    },
    async calculateFee () {
      this.feeCalculating = true
      if (!this.gasPrice || !this.addressIsValid) {
        this.feeCalculating = false
        return null
      }
      try {
        this.feeExceedError = false
        if (this.selectedCurrency.isBaseCurrency) {
          const { gas } = await calculateFeeForTransaction({ blockchain: this.selectedBlockchain.key })
          this.gas = gas
        } else {
          const contract = await getErc20ContractAsync({
            chainId: Number(ChainId[this.selectedBlockchain.key as Blockchain]),
            address: this.selectedCurrency.address
          })
          this.gas = await contract.transfer({
            mode: MethodMode.ESTIMATE_GAS,
            from: this.selectedWallet.address,
            recipient: this.toAddress,
            amount: this.amountInBaseUnits.toString()
          })
        }
        this.fee = new BigNumber(this.gas * GAS_MULTIPLIER).multipliedBy(this.gasPrice).dividedBy(BASE_UNITS_IN_ETH)
        this.feeCalculating = false
      } catch (e: any) {
        if (e.message && e.message.includes('amount exceeds')) {
          this.feeExceedError = true
        } else {
          console.error(e)
          throw e
        }
      }
    },
    calculateFeeDebounce: debounce(function (this: { calculateFee: () => {} }) {
      this.calculateFee()
    }, 400),
    getWalletImage (wallet: Wallet) {
      switch (wallet.group) {
        case WalletGroup.Metamask:
          return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect:
          return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink:
          return '/static/images/wallets/tron-wallet.png'
        default:
          return '/static/images/wallets/cloud-wallet.svg'
      }
    },
    onWalletChange (wallet: Wallet | null) {
      if (wallet) {
        this.selectedWallet = wallet
        this.selectedBlockchain = this.blockchainOptions[0]
        this.selectedCurrency = this.currencyOptions[0]
        this.initAndDestroyWalletConnectIfRequired()
      }
    },
    onBlockchainInput (blockchain: BlockchainOption) {
      if (blockchain) {
        this.selectedBlockchain = blockchain
        this.selectedCurrency = this.currencyOptions[0]
        this.calculateFee()
      }
    },
    onCurrencyInput (currency: Currency) {
      if (currency) {
        this.selectedCurrency = currency
        this.calculateFee()
      }
    },
    onGasPriceInput (price: string) {
      this.gasPrice = price
      this.calculateFeeDebounce()
    },
    async onMaxClick () {
      try {
        this.maxAmountCalculating = true
        await this.$validator.validate('address')
        if (this.errors.has('address') ||
          !this.currentBalances ||
          (await this.calculateFee() === null && this.selectedWallet.group !== WalletGroup.TronLink)) return // example address not filled
        let amount = new BigNumber(0)
        if (this.selectedCurrency.isBaseCurrency) {
          const amountAfforded = this.currentBalances[this.selectedCurrency.name].balance.minus(this.fee)
          if (amountAfforded.isGreaterThan(0)) {
            amount = amountAfforded
          }
        } else {
          amount = this.currentBalances[this.selectedCurrency.name].balance
        }
        this.amount = amount.toFixed(this.selectedCurrency.maxDecimals, BigNumber.ROUND_DOWN).toString()
      } catch (err) {
        console.error(err)
      } finally {
        this.maxAmountCalculating = false
      }
    },
    async onSubmitTronLink () {
      try {
        TronLinkProvider.initWithoutAwait()
        if (!TronLinkProvider.isAvailable) {
          return this.showWalletErrorSnack(
            'TronLink not found. Please install TronLink to continue.'
          )
        }
        const status = await TronLinkProvider.connect()
        if (status.code !== 200) {
          return this.showWalletErrorSnack(
            'Error connecting to TronLink. Please try again.'
          )
        }
        const node = await TronLinkProvider.node()
        if (ProviderByBlockchain[Blockchain.Tron] !== node) {
          return this.showWalletErrorSnack(
            'Selected node in TronLink does not match the current'
          )
        }
        const address = await TronLinkProvider.address()
        if (address?.toLowerCase() !== this.selectedWallet?.address?.toLowerCase()) {
          return this.errors.add({
            field: 'address',
            msg: `Address does not match. Please select your current address.`
          })
        }

        if (this.selectedCurrency.isBaseCurrency) {
          const tx = await TronLinkProvider.tronWeb.transactionBuilder.sendTrx(
            this.toAddress,
            this.amountInBaseUnits
          )
          const signedTx = await TronLinkProvider.tronWeb.trx.sign(tx)
          const broastTx: any = await TronLinkProvider.tronWeb.trx.sendRawTransaction(signedTx)
          await this.awaitTransaction(broastTx.txid)
          this.sendSuccessWalletOperation({
            tx: broastTx.txid,
            currency: this.selectedCurrency,
            blockchain: this.selectedBlockchain.key,
            walletName: getNameByWalletGroup(this.selectedWallet.group),
            amount: this.amount,
          })
          if (this.onSuccess) {
            this.onSuccess({ from: this.selectedWallet.address, to: this.toAddress })
          }
        } else {
          const erc20Contract = await getErc20ContractTronAsync({
            contractType: ContractType.WriteWithTronLink,
            address: this.selectedCurrency.address
          })
          const txId: string = await erc20Contract.transfer({
            from: this.selectedWallet.address,
            recipient: this.toAddress,
            amount: this.amountInBaseUnits
          })
          await this.awaitTransaction(txId)
          this.sendSuccessWalletOperation({
            tx: txId,
            currency: this.selectedCurrency,
            blockchain: this.selectedBlockchain.key,
            walletName: getNameByWalletGroup(this.selectedWallet.group),
            amount: this.amount,
          })
          if (this.onSuccess) {
            this.onSuccess({ from: this.selectedWallet.address, to: this.toAddress })
          }
        }
      } catch (err: any) {
        console.error(err)
        return this.showWalletErrorSnack(
          err.message
        )
      }
    },
    async onSubmit () {
      if (!await this.$validator.validateAll()) {
        return false
      }
      this.errors.clear()
      if (this.isNotEnoughBalance) {
        return this.errors.add({
          field: 'amount',
          msg: 'Please enter an amount no higher than your available balance'
        })
      }
      if (this.selectedWallet.group === WalletGroup.TronLink) {
        this.onSubmitTronLink()
        return
      }
      if (this.selectedWallet.group === WALLET_GROUP_METAMASK) {
        const walletType = 'Metamask'
        if (!MetamaskProvider.isAvailable()) {
          return this.showWalletErrorSnack(
            'Metamask not found or not installed'
          )
        }
        const address = await MetamaskProvider.address()
        if (address?.toLowerCase() !== this.selectedWallet?.address?.toLowerCase()) {
          return this.errors.add({
            field: 'address',
            msg: `Selected address in ${walletType} does not match the current`
          })
        }
        const chainId: number = await MetamaskProvider.chainId() || 0
        const currentChainId = Number(ChainId[this.selectedBlockchain.key as Blockchain])
        if (chainId !== currentChainId) {
          return this.errors.add({
            field: 'blockchain',
            msg: `Selected network ${getBlockchainNameByChainId(chainId)} in ${walletType} does not match current`
          })
        }
      } else if (this.selectedWallet.group === WALLET_GROUP_WALLET_CONNECT) {
        if (!this.wcIsConnected) {
          this.showWalletErrorSnack(
            'WalletConnect not found or not connected'
          )
          return this.showWalletErrorSnack(
            'WalletConnect not found or not connected'
          )
        }

        if (!this.wcAddressesLowerCase.includes(this.selectedWallet?.address?.toLowerCase())) {
          this.showWalletErrorSnack(
            'Connected address(es) via WalletConnect do not match the one selected in the modal'
          )
          return this.errors.add({
            field: 'address',
            msg: `Connected address(es) via WalletConnect do not match the one selected in the modal`
          })
        }

        const selectedNetwork = Number(ChainId[this.selectedBlockchain?.key as Blockchain])
        if (!this.wcChainIds.includes(selectedNetwork)) {
          this.showWalletErrorSnack(
            'Connected network(s) via WalletConnect do not match the one selected in the modal'
          )
          return this.errors.add({
            field: 'blockchain',
            msg: `Connected network(s) via WalletConnect do not match the one selected in the modal`
          })
        }
      }

      const baseBalance = this.currentBalances?.[this.baseCurrency?.backendName]
      console.log('debug', baseBalance, this.fee, this.baseCurrency)
      if (baseBalance && this.fee.isGreaterThan(baseBalance.balance)) {
        const requiredAmount = this.fee.minus(baseBalance.balance).toFixed(5)
        return this.errors.add({
          field: 'blockchain',
          msg: `You don't have enough ${this.baseCurrency.name} to pay the transaction fee. ` +
            `You need to add more than ${requiredAmount} ${this.baseCurrency.name}`
        })
      }

      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Confirm withdraw',
          text: `<div style="max-width: 360px">
          You want to withdraw <b>${this.amount} ${this.selectedCurrency.name}</b> to the wallet <b>${this.toAddress}</b>
          </div>`,
          buttons: [new Button({
            text: 'Confirm',
            classes: 'lx-blue-btn',
            onClick: async () => {
              let link = ''
              this.withdrawing = true
              try {
                await notifiableRequest({
                  // @ts-ignore
                  title: 'Withdrawal',
                  successText: 'Your withdrawal has been successful.',
                  failureText: () => link
                    ? `Unsuccessful <a href="${link}" target="_blank" rel="noopener nofollow"
                  style="text-decoration:none;">transaction</a>`
                    : 'Your withdrawal has unsuccessful. Please try again.',
                  request: async () => {
                    let tx = ''
                    const transactionData = await this.signTransaction()
                    const deferred = new Deferred<string>()
                    transactionData.eventEmitter
                      .on('transactionHash', (hash: string) => {
                        deferred.resolve(hash)
                      })
                      .on('error', (err: any) => {
                        console.error(err)
                        deferred.reject(err)
                      })
                    tx = await deferred.promise
                    link = getTxLink({ blockchain: this.selectedBlockchain.key, tx })
                    await this.awaitTransaction(tx)
                    this.sendSuccessWalletOperation({
                      tx,
                      currency: this.selectedCurrency,
                      blockchain: this.selectedBlockchain.key,
                      walletName: getNameByWalletGroup(this.selectedWallet.group),
                      amount: this.amount,
                    })
                    if (this.onSuccess) {
                      this.onSuccess({ from: this.selectedWallet.address, to: this.toAddress })
                    }
                    this.$emit('close')
                  }
                })
              } catch (e) {
                console.error(e)
                this.withdrawing = false
              }
            }
          })]
        }
      })
    },
    showWalletErrorSnack (text: string) {
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text,
      })
    },
    async signTransaction (key: string) {
      const chainId: number = Number(ChainId[this.selectedBlockchain.key as Blockchain])
      if (this.selectedCurrency.isBaseCurrency) {
        console.log('signTransaction txSend', {
          walletGroup: this.selectedWallet.group as WalletGroup,
          chainId,
          from: this.selectedWallet.address,
          to: this.toAddress,
          value: this.amountInBaseUnits.toString(),
          encodedAbi: '',
          gasPrice: this.gasPrice as string,
          gasLimit: 21000
        })
        const data = await txSend({
          walletGroup: this.selectedWallet.group as WalletGroup,
          chainId,
          from: this.selectedWallet.address,
          to: this.toAddress,
          value: this.amountInBaseUnits.toString(),
          encodedAbi: '',
          gasPrice: this.gasPrice as string,
          gasLimit: 21000
        })
        console.log('signTransaction data', data)
        return data
      } else {
        const contract = await getErc20ContractAsync({
          chainId,
          address: this.selectedCurrency.address
        })
        const encodedAbi = contract.transfer({
          mode: MethodMode.ENCODE_ABI,
          from: this.selectedWallet.address,
          recipient: this.toAddress,
          amount: this.amountInBaseUnits.toString()
        }) as string
        console.log('signTransaction txSend', {
          walletGroup: this.selectedWallet.group as WalletGroup,
          chainId,
          from: this.selectedWallet.address,
          to: this.selectedCurrency.address,
          value: '0',
          encodedAbi,
          gasPrice: this.gasPrice as string,
          gasLimit: parseInt(new BigNumber(this.gas).multipliedBy(GAS_MULTIPLIER).toFixed(0))
        })
        const data = await txSend({
          walletGroup: this.selectedWallet.group as WalletGroup,
          chainId,
          from: this.selectedWallet.address,
          to: this.selectedCurrency.address,
          value: '0',
          encodedAbi,
          gasPrice: this.gasPrice as string,
          gasLimit: parseInt(new BigNumber(this.gas).multipliedBy(GAS_MULTIPLIER).toFixed(0))
        })
        console.log('signTransaction data', data)
        return data
      }
    },
    async awaitTransaction (tx: string) {
      const link = this.selectedBlockchain.key === Blockchain.Tron
        ? getTxLinkTron({ tx })
        : getTxLink({ blockchain: this.selectedBlockchain.key, tx })
      try {
        const pendingTx = new PendingTx({
          typeAwait: TypeAwait.FromBlockchain,
          txId: tx,
          params: { blockchain: this.selectedBlockchain.key, txId: tx },
          title: 'Transaction',
          text: `Waiting for <a href="${link}" target="_blank" rel="noopener nofollow" style="text-decoration:none;">transaction</a> confirmation from the network`, // eslint-disable-line
        })
        await this.addAndAwaitTx(pendingTx)
        this.withdrawing = false
      } finally {
        this.closeSnackbar(tx)
      }
    },
    sendSuccessWalletOperation ({ tx, currency, blockchain, walletName, amount }: any) {
      googleAnalyticsV2.send({
        event: 'wallet-withdraw',
        'event-content': tx,
        // @ts-ignore-next-line
        block_type: BlockchainNames[blockchain],
        currency: currency.name,
        sum: convertToUsd({
          value: amount,
          currency,
          rates: this.rates,
        }),
        wallet_integration_type: walletName,
      })
    },
  },
})
