export default class Button {
  text: String
  classes?: Array<string> | string
  onClick: () => void

  constructor ({ text, classes, onClick }: Partial<Button>) {
    this.text = text || ''
    this.classes = classes
    this.onClick = onClick || (() => {})
  }
}
